@charset "UTF-8";
/*
Theme Name: Edupls Education HTML5 Template
Theme URI:
Design by: Uzzol
Developed by: Asadur Jaman Nur
*/
/*
01. Theme Base
    1.1.Mixin
    1.2. Variable
    1.3. Typography
    1.4. Google Fonts
0.2. Reset
    2.1. Container
    2.2. Grid
    2.3. input
    2.4. Swiper Slider
    2.5. Mobile menu
0.3. Utilities
    3.1. Buttons
    3.2. Titles
    3.3. Common
    3.4. Font
    3.5. Background
    3.6. Text color
    3.7. Ovarlay
    3.8. Animation
0.4. Template style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Pagination
    4.5. Blog 
    4.6. Header Top
    4.7. Content Info
    4.8. User Login
    4.9. Main Menu
    4.10. Banner
    4.11. Play Data
    4.12. Case Studies
    4.13. About Us
    4.14. Courses Plan
    4.15. How It Work
    4.16. CTA
    4.17. Why Choose Us
    4.18. Star
    4.19. My Accordion
    4.20. Add Slide
    4.21. Student Feedback
    4.22. Our Blog
    4.23. Brands
    4.24. Our Best Team
    4.25. Skill Section
    4.26. Make An Appointment
    4.27. Check Box
    4.28. Features
    4.29. Pricing Plan
    4.30. Progress Bar
    4.31. Skill Items
    4.32. Education Video Watch
    4.33. Courses
    4.34. Our Events
    4.35. Asked Question
    4.36. Latests News
    4.37. Google Map
    4.38. Register Now
    4.39. Client Say
    4.40. Breadcum
    4.41. Team Details
    4.42. Events
    4.43. Get Tickets
*/
/* ===============
    Google Fonts
   =============== */
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap");

/* =================
    Theme Base
   =================*/
:root {
  --color-primary: #179BD7;
  --color-secondary: #1C1C1C;
  --color-paragraph: #7B7E86;
  --color-white: #ffffff;
  --color-white2: #F6FDFF;
  --color-white3: #F4F4F4;
  --color-white4: #C0C6D7;
  --color-white5: #f6f7fa;
  --color-white6: #f5fbfd;
  --color-white7: #e6e6e6;
  --color-blue: #179BD7;
  --color-black: #1C1C1C;
  --color-black2: #7B7E86;
  --color-black3: #1C1C25;
  --color-border: #DDE0E9;
  --title-font: "Kanit", sans-serif;
  --body-font: "Kumbh sans", sans-serif;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

/* ================
    Default Value
   ================ */
h1 {
  font-size: 60px;
  line-height: 70px;
}

h2 {
  font-size: 48px;
  line-height: 58px;
}

h3 {
  font-size: 36px;
  line-height: 46px;
}

h4 {
  font-size: 30px;
  line-height: 40px;
}

h5 {
  font-size: 24px;
  line-height: 34px;
}

h6 {
  font-size: 20px;
  line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
  color: var(--color-secondary);
  text-transform: none;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

/*====================
	 Default
======================*/
*,
html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
big,
cite,
code,
del,
img,
q,
small,
strong,
sub,
sup,
tt,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
footer,
header,
menu,
nav,
section,
mark,
audio,
video,
::before,
::after {
  margin: 0;
  padding: 0;
  border: 0;
}

*,
::before,
::after {
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: middle;
}

iframe {
  max-width: 100%;
}

del {
  text-decoration: line-through;
}

table {
  width: 100%;
  max-width: 100%;
}

table th {
  vertical-align: top;
}

pre {
  display: block;
  word-break: break-all;
  word-wrap: break-word;
}

textarea {
  resize: initial;
}

b,
strong {
  font-weight: 700;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

input:focus,
.uneditable-input:focus,
select:focus,
textarea:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0em rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0em rgba(0, 123, 255, 0.25);
}

input[type=submit] {
  padding: 13px 15px;
  height: 40px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.5px;
  border: none;
}

input[type=submit],
input[type=button] {
  cursor: pointer;
}

:root h1,
:root h2,
:root h3,
:root h4,
:root h5,
:root h6,
:root p {
  margin: 0;
  padding: 0;
}

:root a {
  text-decoration: none;
  font-family: var(--title-font);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: var(--color-black);
}

:root a:hover {
  color: var(--color-primary);
}

:root .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
}

/*=================================
    02. Reset
==================================*/
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-auto {
  padding: 0 10px;
}

.container,
.container-fluid {
  width: 100%;
  padding: 0 10px;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1220px;
  }
}

.all-btn .btn-p {
  /*Button Padding*/
  /*Button Version*/
  /*Button Version color*/
  /*Button Border Radius*/
  /*Button Icon*/
}

.all-btn .btn-p.v1,
.all-btn .btn-p.v3 {
  padding: 14px 24px;
}

.all-btn .btn-p.v2 {
  padding: 6px 16px;
}

.all-btn .btn-p.v1,
.all-btn .btn-p.v2 {
  font-family: var(--title-font);
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.32px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block;
}

.all-btn .btn-p.v3 {
  font-family: var(--title-font);
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.32px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block;
}

.all-btn .btn-p.btn-blue {
  color: var(--color-white);
  background: var(--color-primary);
}

.all-btn .btn-p.btn-blue:hover {
  background: var(--color-black);
  color: var(--color-white);
}

.all-btn .btn-p.btn-blue2 {
  color: var(--color-white);
  background: var(--color-primary);
}

.all-btn .btn-p.btn-blue2:hover {
  background: var(--color-white);
  color: var(--color-primary);
}

.all-btn .btn-p.btn-black {
  background: var(--color-black);
  color: var(--color-white);
}

.all-btn .btn-p.btn-black:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.all-btn .btn-p.btn-white {
  background: var(--color-white);
  color: var(--color-primary);
}

.all-btn .btn-p.btn-white:hover {
  background: var(--color-black);
  color: var(--color-white);
}

.all-btn .btn-p.btn-l-grey {
  background: #f4f4f4;
  color: var(--color-black);
}

.all-btn .btn-p.btn-l-grey:hover {
  background: none;
  position: relative;
}

.all-btn .btn-p.btn-l-grey:hover::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #f4f4f4;
}

.all-btn .btn-p.rounded {
  border-radius: 5px;
}

.all-btn .btn-p.rounded-full {
  border-radius: 100px;
}

.all-btn .btn-p.icon-v1::after {
  /* font-family: "icomoon";
  content: "";
  font-size: calc(100% - 2px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: 10px; */
}

.all-btn .btn-p.icon-v2::after {
  font-family: "icomoon";
  content: "";
  font-size: calc(100% - 2px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: 10px;
  color: var(--color-primary);
}

/*---- Extra button ----*/
.btn-p.v3 {
  color: var(--color-primary);
  position: relative;
  z-index: 1;
}

.btn-p.v3::before {
  background: var(--color-white);
  position: absolute;
  content: "";
  -webkit-box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 10px;
  -webkit-transform: translate(-50%, -50%) skew(-8deg);
  transform: translate(-50%, -50%) skew(-8deg);
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.btn-p.v3::after {
  /* font-family: "icomoon";
  content: "";
  font-size: calc(100% - 2px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: 10px; */
}

.btn-p.v3:hover::before {
  -webkit-transform: translate(-50%, -50%) skew(8deg);
  transform: translate(-50%, -50%) skew(8deg);
}

.btn-p.v6 {
  position: relative;
  z-index: 1;
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-black3);
  display: inline-block;
}

.btn-p.v6::after {
  font-family: "icomoon";
  content: "";
  font-size: calc(100% - 2px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  position: absolute;
  top: 4px;
  left: 100%;
  color: var(--color-primary);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.btn-p.v6::before {
  position: absolute;
  right: -30px;
  top: 3px;
  content: "";
  width: 20px;
  height: 20px;
  background: #b8d4e4;
  border-radius: 50%;
  z-index: -1;
}

.btn-p.v6:hover::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.all-btn.v2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.read-more-btn-p {
  color: #141414;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.read-more-btn-p .read-more-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  min-width: 45px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: var(--color-white);
  border: 1px solid rgba(237, 237, 237, 0.89);
  line-height: 0;
}

.section-title {
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.section-title.start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.section-title.center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section-title.end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.section-title .section-title-inner.start {
  text-align: start;
}

.section-title .section-title-inner.center {
  text-align: center;
}

.section-title .section-title-inner.end {
  text-align: end;
}

.section-title .section-title-inner .sub-title {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-primary);
  padding: 3px 20px;
  background: var(--color-white);
  border: 1px solid #f6f5f5;
  -webkit-box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.section-title .section-title-inner .sub-titlev2 {
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2.43px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.section-title .section-title-inner .sub-titlev2 span {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.7px;
  color: var(--color-primary);
  padding: 0 8px;
  background: var(--color-white);
  border: 1px solid #f6f5f5;
  -webkit-box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: var(--title-font);
}

.section-title .section-title-inner .sub-titlev2.color-white {
  color: var(--color-white);
}

.section-title .section-title-inner .sub-titlev3 {
  color: var(--color-white);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2.43px;
  text-transform: uppercase;
  gap: 10px;
  margin-bottom: 15px;
}

.section-title .section-title-inner .big-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
}

.section-title .section-title-inner .title-para {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-top: 22px;
  font-family: var(--body-font);
}

.section-title .section-title-inner .title-para.mb-30 {
  margin-bottom: 30px;
}

.section-title .section-title-inner .title-para.sp-color-black {
  color: var(--color-paragraph);
}

.section-title .section-title-inner .title-para.sp-color-white {
  color: var(--color-white4);
}

.section-title .s-color-white {
  color: var(--color-white);
}

.section-title.v2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*-------------------
      Header Top
  -------------------*/
.header-one-top-area {
  background: var(--color-primary);
}

.header-two-top-area,
.header-three-top-area {
  background: #142439;
  border-bottom: 1px solid #384350;
}

.header-three-top-area {
  background: #121830;
}

/*-------------------
    User Login
  -------------------*/
.user-login {
  /* display: none; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 7px;
  margin: 0;
  padding: 0;
}

.user-login li {
  color: var(--color-white);
}

.user-login li:first-child {
  margin-right: 1px;
}

.user-login li:last-child {
  position: relative;
}

.user-login li:last-child::before {
  content: "/";
  position: absolute;
  left: -7px;
  color: var(--color-white);
}

.user-login li a {
  color: var(--color-white);
  text-decoration: none;
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.user-login li:hover a {
  color: var(--color-black);
}

/*-------------------
      Header Top Content
  -------------------*/
.header-top-content.v1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 13px 0;
}

.header-top-content.v1 .discount-the-month {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-white);
}
@media (max-width: 767px) {
  .header-top-content.v1 .discount-the-month {
    font-family: var(--body-font);
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: var(--color-white);
  }
}
.header-top-content.v2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

.header-top-content.v2 .para {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.header-top-content.v2 .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 18px;
}

.header-top-content.v2 .contact-info li {
  color: var(--color-white);
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

/*-------------------
  Follow Us Link
  -------------------*/
.follow-us-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  display: none;
}

.follow-us-link .follow-icon {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.follow-us-link .follow-icon a {
  color: var(--color-white);
}

.follow-us-link .follow-icon:hover a {
  color: var(--color-primary);
}

.nav-middle-content {
  display: none;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.nav-middle-content .content-location {
  border-right: 1px dotted var(--color-border);
  padding-right: 63px;
  margin-left: 50px;
}

.nav-middle-content .content-address {
  margin-left: 30px;
}

.nav-middle-content .content-location,
.nav-middle-content .content-address {
  gap: 10px;
}

.nav-middle-content .content-location .info-data a,
.nav-middle-content .content-address .info-data a {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-primary);
}

.nav-middle-content .content-location .info-data .para,
.nav-middle-content .content-address .info-data .para {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-paragraph);
}

.nav-middle-content .content-location .location-icon,
.nav-middle-content .content-location .address-icon,
.nav-middle-content .content-address .location-icon,
.nav-middle-content .content-address .address-icon {
  background: var(--color-primary);
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-white);
}

/*-------------------
    Top Content Info
  -------------------*/
.top-nav-search-option {
  border-radius: 10px;
  overflow: hidden;
  display: none;
}

.top-nav-search-option form {
  position: relative;
  overflow: hidden;
  width: 80%;
  margin-left: auto;
}

.top-nav-search-option form input {
  padding: 14px 23px;
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  overflow: hidden;
  background: #f6fdff;
}

.top-nav-search-option form input::-webkit-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.top-nav-search-option form input::-moz-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.top-nav-search-option form input:-ms-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.top-nav-search-option form input::-ms-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.top-nav-search-option form input::placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.top-nav-search-option form .search-btn-nav {
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  height: 100%;
  background: var(--color-primary);
  color: var(--color-white);
  border: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  color: var(--color-white);
}

.top-content-info {
  padding: 20px 0;
}

.top-content-info .logo-img {
  width: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.top-content-info .logo-img img{
  width: 100%;
}

/*-------------------
    Menu Social
  -------------------*/
.menu-social {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.menu-social li {
  border-radius: 5px;
  overflow: hidden;
}

.menu-social li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: var(--color-white);
  color: var(--color-primary);
  font-size: 14px;
}

.menu-social li a:hover {
  color: var(--color-black);
}

/*-------------------
  Menu Contact Number
  -------------------*/
.menu-contact-number {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
}

.menu-contact-number a {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-white);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.menu-contact-number::before {
  /* font-family: "icomoon";
  content: "";
  speak: never;
  font-size: calc(100% + 10px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 5px;
  color: var(--color-white);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; */
}

.menu-contact-number:hover a,
.menu-contact-number:hover::before {
  color: var(--color-black);
}

/*-------------------
    Header Login Regi
  -------------------*/
.header-login-regi a,
.header-search-button a {
  width: 34px;
  height: 34px;
  background: var(--color-primary);
  color: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.header-login-regi a:hover,
.header-search-button a:hover {
  background: var(--color-white);
  color: var(--color-primary);
}

.menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 20px;
  border: none;
  display: inline-block;
  border-radius: 5px;
  background: var(--color-white3);
  color: var(--color-primary);
}

.header-layout1 .menu-area {
  display: none;
}

.header-layout1 .menu-area-content {
  padding-right: 20px;
  margin-bottom: -35px;
  position: relative;
  z-index: 1;
}

.header-layout1 .menu-area-content::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
  background: var(--color-primary);
  -webkit-transform: skew(-8deg);
  transform: skew(-8deg);
  z-index: -1;
  border-radius: 10px;
}

.header-layout1 .menu-area-content .main-menu {
  padding-left: 20px;
}

.header-layout1 .menu-area-content .main-menu>ul>li>a {
  color: var(--color-white);
  font-size: 16px;
  text-transform: uppercase;
}

.header-layout1 .menu-area-content .menu-area-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 40px;
}

.header-layout1 .menu-area-content .main-menu>ul>li>a {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header-layout1 .menu-area-content .main-menu>ul>li>a:hover {
  color: var(--color-black);
}

.header-layout1 .menu-area-content .main-menu>ul>li.menu-item-has-children>a:after {
  font-family: "icomoon";
  content: "";
  speak: never;
  font-size: calc(100% - 8px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  margin-left: 5px;
}

.header-layout2 {
  position: relative;
}

.header-layout2 .menu-area {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  border-bottom: 1px solid rgba(252, 252, 252, 0.11);
  padding: 10px 0;
}

.header-layout2 .menu-area-content .menu-area-right-content {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}

.header-layout2 .main-menu a {
  color: var(--color-white);
}

.header-layout2 .main-menu>ul>li>a {
  text-transform: uppercase;
}

.header-layout2 .main-menu>ul>li.menu-item-has-children>a:after {
  font-family: "icomoon";
  content: "";
  speak: never;
  font-size: calc(100% - 5px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  margin-left: 5px;
}

.header-layout3 .menu-area-content .menu-area-right-content {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}

.header-layout3 .main-menu>ul>li>a {
  padding: 35px 0;
}

.header-layout3 .main-menu>ul>li>a {
  text-transform: uppercase;
}

.header-layout3 .main-menu>ul>li.menu-item-has-children>a:after {
  font-family: "icomoon";
  content: "";
  speak: never;
  font-size: calc(100% - 5px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  margin-left: 5px;
}

.search-option-box {
  position: relative;
  width: 100%;
  max-width: 34px;
  height: 34px;
  border-radius: 100px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border: 1px solid var(--color-border);
}

.search-option-box.open {
  max-width: 140px;
}

.search-option-box input {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  padding: 0 15px;
  border: none;
  border-radius: 100px;
  outline: none;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.search-option-box.open input {
  padding: 0 10px 0 45px;
}

.search-option-box .search {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 100px;
  overflow: hidden;
  cursor: pointer;
}

.search-option-box.open .search {
  border-radius: 100px;
}

.search .search-icon {
  font-size: 30px;
  color: var(--color-secondary);
}

.search-option-box .close-icon {
  position: absolute;
  top: 50%;
  right: 3px;
  font-size: 30px;
  color: var(--color-secondary);
  padding: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  font-size: 12px;
}

.search-option-box.open .close-icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  pointer-events: auto;
  opacity: 1;
}

/*-------------------
       Banner Content
    -------------------*/
.banner-content.v1 {
  padding: 100px 0;
}

.banner-content.v1 .section-title .sub-titlev2 {
  margin-bottom: 24px;
}

.banner-content.v1 .section-title .big-title {
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
}

.banner-content.v1 .section-title .title-para {
  font-family: var(--body-font);
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  width: calc(100% - 80px);
}

.banner-content.v2 .banner-img {
  padding-top: 202px;
  padding-bottom: 167px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.banner-content.v2 .banner-img::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(69deg, rgba(24, 43, 73, 0.21) 39.49%, rgba(23, 43, 68, 0.82) 78.73%);
}

.banner-content.v2 .section-title {
  margin-bottom: 58px;
}

.banner-content.v2 .section-title .sub-titlev2 {
  margin-bottom: 23px;
}

.banner-content.v2 .section-title .big-title {
  color: var(--color-white);
  font-size: 48px;
  line-height: 58px;
  font-weight: 500;
}

.banner-content.v2 .section-title .title-para {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 27px;
}

.banner-content.v2 .swiper-banner .swiper-button-next {
  display: none;
  width: 67px;
  height: 67px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
  right: 60px;
}

.banner-content.v2 .swiper-banner .swiper-button-next::after {
  font-family: "icomoon";
  content: "";
  font-size: 20px;
}

.banner-content.v2 .swiper-banner .swiper-button-prev {
  display: none;
  width: 67px;
  height: 67px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
  left: 60px;
}

.banner-content.v2 .swiper-banner .swiper-button-prev::after {
  font-family: "icomoon";
  content: "";
  font-size: 20px;
}

.banner-content.v3 {
  position: relative;
  z-index: 0;
}

.banner-content.v3 .banner-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  padding: 120px 0;
  position: relative;
  z-index: 1;
}

.banner-content.v3 .banner-img::before {
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(91deg, rgba(20, 36, 57, 0.79) 37.92%, rgba(18, 31, 47, 0.79) 63.95%);
  z-index: -1;
}

.banner-content.v3 .banner-img .slider-boder {
  left: 20%;
}

.banner-content.v3 .banner-img .slider-boder2 {
  left: 40%;
}

.banner-content.v3 .banner-img .slider-boder3 {
  left: 60%;
}

.banner-content.v3 .banner-img .slider-boder4 {
  left: 80%;
}

.banner-content.v3 .banner-img .slider-boder,
.banner-content.v3 .banner-img .slider-boder2,
.banner-content.v3 .banner-img .slider-boder3,
.banner-content.v3 .banner-img .slider-boder4 {
  height: 100%;
  width: 1px;
  background: rgba(166, 166, 166, 0.44);
  position: absolute;
  top: 0;
  z-index: -1;
}

.banner-content.v3 .banner-img .slider-boder::after,
.banner-content.v3 .banner-img .slider-boder3::after {
  content: "";
  height: 50px;
  width: 1px;
  right: 0px;
  background: var(--color-white);
  bottom: 50px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: absolute;
  -webkit-animation: move 3s infinite linear;
  animation: move 3s infinite linear;
}

@-webkit-keyframes move {
  from {
    top: 0;
  }

  to {
    top: 100%;
  }
}

@keyframes move {
  from {
    top: 0;
  }

  to {
    top: 100%;
  }
}

@keyframes move {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }
}

.banner-content.v3 .banner-img .slider-boder2::after,
.banner-content.v3 .banner-img .slider-boder4::after {
  content: "";
  height: 50px;
  width: 1px;
  right: 0px;
  background: var(--color-white);
  bottom: 50px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: absolute;
  -webkit-animation: movetwo 3s infinite linear;
  animation: movetwo 3s infinite linear;
}

@-webkit-keyframes movetwo {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }
}

@keyframes movetwo {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }
}

@keyframes movetwo {
  from {
    top: 0;
  }

  to {
    top: 100%;
  }
}

.banner-content.v3 .section-title {
  margin-bottom: 34px;
}

.banner-content.v3 .section-title .big-title {
  color: var(--color-white);
  font-size: 48px;
  line-height: 58px;
  font-weight: 500;
}

.banner-content.v3 .all-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.banner-content.v3 .swiper-banner .swiper-button-next {
  display: none;
  width: 67px;
  height: 67px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
  right: 60px;
}

.banner-content.v3 .swiper-banner .swiper-button-next::after {
  font-family: "icomoon";
  content: "";
  font-size: 20px;
}

.banner-content.v3 .swiper-banner .swiper-button-prev {
  display: none;
  width: 67px;
  height: 67px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
  left: 60px;
}

.banner-content.v3 .swiper-banner .swiper-button-prev::after {
  font-family: "icomoon";
  content: "";
  font-size: 20px;
}

/*-------------------
    Category
  -------------------*/
.catagoey {
  margin-top: 110px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 25px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.catagoey .catagoey-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  padding: 22px;
  border-radius: 10px;
  border: 1px solid var(--color-white);
  background: linear-gradient(91deg, rgba(19, 35, 53, 0.14) 40.72%, rgba(19, 34, 52, 0.14) 68.25%);
  -webkit-backdrop-filter: blur(6.5px);
  backdrop-filter: blur(6.5px);
}

.catagoey .catagoey-data .category-icon {
  font-size: 30px;
  color: var(--color-primary);
}

.catagoey .catagoey-data .title {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}

.catagoey .catagoey-data .title::after {
  font-family: "icomoon";
  content: "";
  font-size: calc(100% - 2px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  margin-left: 10px;
}

.catagoey .catagoey-data .title:hover {
  color: var(--color-primary);
}

/*-------------------
    messenger Icon
  -------------------*/
.messenger-icon {
  display: none;
  position: absolute;
  bottom: 110px;
  right: 46px;
  background: var(--color-primary);
  width: 104px;
  height: 104px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 54px;
}

/*-------------------
    Banner
  -------------------*/
.banner.v1 {
  background: #1e1f21;
  position: relative;
  z-index: 1;
}

.banner.v1 .bg-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner.v1 .bg-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.banner.v1 .banner-right-content {
  display: none;
  position: relative;
  margin-left: -64px;
  margin-top: -38px;
  z-index: 1;
}

.banner.v1 .banner-right-content .banner-img-bg-circle {
  position: absolute;
  top: -67px;
  right: -135px;
  z-index: -1;
}
.banner-img-bg-circle{
  animation: circle 30s forwards infinite ;
}
@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.middle-img-bg-circle{
  animation:middle 30s forwards infinite ;
}
@keyframes middle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.banner.v1 .banner-right-content .interactive-courses {
  position: absolute;
  right: -237px;
  top: 140px;
  background: var(--color-white);
  width: 172px;
  text-align: center;
  padding: 18px 0 14px 0;
  border-radius: 24px;
  -webkit-animation: example2 5s infinite ease-in-out;
  animation: example2 5s infinite ease-in-out;
}

.banner.v1 .banner-right-content .interactive-courses .courses-content .title {
  margin-top: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-paragraph);
}

.banner.v1 .banner-right-content .interactive-courses .courses-content .para {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-paragraph);
}

@-webkit-keyframes example2 {
  0% {
    right: -237px;
    top: 140px;
  }

  50% {
    right: -237px;
    top: 70px;
  }

  100% {
    right: -237px;
    top: 140px;
  }
}

@keyframes example2 {
  0% {
    right: -237px;
    top: 140px;
  }

  50% {
    right: -237px;
    top: 70px;
  }

  100% {
    right: -237px;
    top: 140px;
  }
}

.banner.v1 .banner-right-content .students {
  position: absolute;
  left: -124px;
  bottom: 75px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05), 0px 4px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05), 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  width: 44%;
  padding: 14px 34px;
}

.banner.v1 .banner-right-content .students .students-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
}

.banner.v1 .banner-right-content .students .students-icon .title {
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-primary);
}

.banner.v1 .banner-right-content .students .students-imgages {
  margin-top: 8px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner.v1 .banner-right-content .students .students-imgages li {
  margin-left: -19px;
}

.banner.v1 .banner-right-content .students .students-imgages li:first-child {
  margin-left: 0;
}

.banner.v1 .banner-right-content .students .students-imgages li img {
  width: 54px;
  height: 54px;
  border: 2px solid var(--color-white);
  border-radius: 100%;
}

.banner.v1 .banner-bg-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: none;
}

.banner.v1 .banner-bg-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  display: none;
}

.banner.v2 {
  position: relative;
  z-index: 1;
}

/*-------------------------
    Banner congratulations
  -------------------------*/
.banner-congratulations {
  position: absolute;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  background: var(--color-white);
  padding: 10px 15px;
  width: 41%;
  border-radius: 24px;
  -webkit-animation: example 4s infinite ease-in-out;
  animation: example 4s infinite ease-in-out;
}

.banner-congratulations .congratulations-icon {
  min-width: 41px;
  height: 41px;
  background: var(--color-black);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner-congratulations .congratulations-content .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-paragraph);
}

.banner-congratulations .congratulations-content .para {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-paragraph);
}

@-webkit-keyframes example {
  0% {
    left: 0px;
    top: 0px;
  }

  50% {
    top: 50px;
    top: 50px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes example {
  0% {
    left: 0px;
    top: 0px;
  }

  50% {
    top: 50px;
    top: 50px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}

/*-------------------
       Play Data
    -------------------*/
.play-data {
  margin-top: 37px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.play-data.v1 .play-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
}

.play-data.v1 .play-content .play-icon {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background: var(--color-white);
  color: var(--color-primary);
  font-size: 30px;
}

.play-data.v1 .play-content .play-icon::before,
.play-data.v1 .play-content .play-icon::after {
  background: var(--color-white);
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -webkit-animation: pulsepaly 4s linear infinite;
  animation: pulsepaly 4s linear infinite;
  z-index: -1;
}

@-webkit-keyframes pulsepaly {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulsepaly {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.play-data.v1 .play-content .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-white);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.play-data.v1 .play-content:hover .title {
  color: var(--color-primary);
}

.play-data.v2 .play-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 19px;
}

.play-data.v2 .play-content .play-icon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background: var(--color-primary);
  color: var(--color-white);
}

.play-data.v2 .play-content .play-icon::before,
.play-data.v2 .play-content .play-icon::after {
  border: 1px solid #e0e7f2;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -webkit-animation: pulsepaly 4s linear infinite;
  animation: pulsepaly 4s linear infinite;
  z-index: -1;
}

@keyframes pulsepaly {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.play-data.v2 .play-content .title {
  color: var(--color-secondary);
  font-family: var(--title-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/*-------------------
    Case Studies Cards
  -------------------*/
.case-studies-cards.v1 .case-studies-card {
  padding: 40px 0px;
  -webkit-box-shadow: 0px 29px 52px rgba(28, 33, 48, 0.1);
  box-shadow: 0px 29px 52px rgba(28, 33, 48, 0.1);
  border-radius: 10px;
  border-bottom: 3px solid #15749f;
  background: var(--color-white);
}

.case-studies-cards.v1 .case-studies-card .card-img {
  padding: 0 40px;
}

.case-studies-cards.v1 .case-studies-card .card-img img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

.case-studies-cards.v1 .case-studies-card .middle-design {
  margin-top: 18px;
}

.case-studies-cards.v1 .case-studies-card .middle-design img {
  width: 100%;
}

.case-studies-cards.v1 .case-studies-card .card-content {
  margin-top: 34px;
  padding: 0 10px 0 30px;
}

.case-studies-cards.v1 .case-studies-card .card-content .para {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-paragraph);
  font-family: var(--title-font);
  margin-bottom: 6px;
  text-transform: uppercase;
}

.case-studies-cards.v1 .case-studies-card .card-content .title {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 15px;
}

.case-studies-cards.v2 .case-studies-card {
  text-align: center;
  padding: 50px 20px;
  border-radius: 30px;
  background: var(--color-white);
  border-radius: 30px;
  border: 1px solid #e1e8f3;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.case-studies-cards.v2 .case-studies-card .item-icon {
  margin-bottom: 25px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-size: 90px;
  color: var(--color-primary);
}

.case-studies-cards.v2 .case-studies-card .item-title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 19px;
}

.case-studies-cards.v2 .case-studies-card .item-para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 26px;
}

.case-studies-cards.v2 .case-studies-card .bg-flower {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: -1;
  display: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.case-studies-cards.v2 .case-studies-card .btn-white {
  border: 0.5px solid #e1e8f3;
}

.case-studies-cards.v2 .case-studies-card:hover {
  -webkit-box-shadow: 0px 4px 51px 0px rgba(207, 214, 227, 0.52);
  box-shadow: 0px 4px 51px 0px rgba(207, 214, 227, 0.52);
  border: 1px solid var(--color-white);
}

.case-studies-cards.v2 .case-studies-card:hover .item-icon {
  overflow: hidden;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.case-studies-cards.v2 .case-studies-card:hover .bg-flower {
  display: block;
}

.case-studies-cards.v2 .case-studies-card:hover .btn-white {
  background: var(--color-primary);
  color: var(--color-white);
}

.case-studies-cards.v2 .case-studies-card .btn-p {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--body-font);
}

/*-------------------
       Case Studies
    -------------------*/
.case-studies-content .section-title {
  margin-bottom: 32px;
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 26px;
  margin-bottom: 24px;
  padding-bottom: 21px;
  border-bottom: 1px solid rgba(140, 140, 140, 0.3);
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content .case-icon {
  display: none;
  font-size: 56px;
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content .content-data .title {
  font-size: 26px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 10px;
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content .content-data .title a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content .content-data .title a span {
  font-size: 16px;
  line-height: 26px;
  font-weight: 900;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content .content-data .para {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content a {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content:hover a {
  color: var(--color-primary);
}

.case-studies-content .case-studies-left-content .case-studies-contents .case-content:hover a span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.case-studies-content .case-studies-right-content {
  margin-top: 34px;
}

.case-studies-content .case-studies-right-content .big-title {
  font-size: 140px;
  -webkit-text-stroke: 0.3px #ddd;
  color: var(--color-white);
  margin-bottom: 15px;
}

.case-studies-content .case-studies-right-content .wining-award {
  font-size: 25px;
  top: 0;
}

.case-studies-content .case-studies-right-content .case-studies-right-img {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.case-studies-content .case-studies-right-content .case-studies-right-img:hover>img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.case-studies-content .case-studies-right-content .case-studies-right-img img {
  width: 100%;
  height: 420px;
  max-height: 700px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.case-studies-content .case-studies-right-content .case-studies-right-img .discover-more {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--color-primary);
  padding: 26px 30px;
  border-radius: 10px 0 20px 0;
  overflow: hidden;
  z-index: 1;
}

.case-studies-content .case-studies-right-content .case-studies-right-img .discover-more .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 20px;
}

.case-studies-content .case-studies-right-content .case-studies-right-img .discover-more .right-img-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

/*-------------------
       Case Studies
   -------------------*/
.case-studies.v1 {
  background-image: -webkit-gradient(linear, left top, right top, from(#f6f7fa), color-stop(35%, #f6f7fa), to(#f6f7fa));
  background-image: linear-gradient(90deg, #f6f7fa 0%, #f6f7fa 35%, #f6f7fa 100%);
  background-size: 100% 700px;
  background-repeat: no-repeat;
}

/*-------------------
       About Us
   -------------------*/
.awards li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 22px;
  margin-bottom: 21px;
}

.awards li:last-child {
  margin-bottom: 0;
}

.awards li .award-img img {
  border-radius: 10px;
  width: 146px;
  height: 122px;
}

.awards li .award-content .award-date {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: var(--color-black);
  margin-bottom: 20px;
}

.awards li .award-content .award-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: var(--color-black);
}

.awards li .award-content .award-para {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-paragraph);
}

/*-------------------
       About Us
   -------------------*/
.about-us-content.v1 .about-left-content {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 24px;
}

.about-us-content.v1 .about-left-content .content-box-1,
.about-us-content.v1 .about-left-content .content-box-2,
.about-us-content.v1 .about-left-content .content-img-1,
.about-us-content.v1 .about-left-content .content-img-2 {
  border-radius: 20px;
  overflow: hidden;
}

.about-us-content.v1 .about-left-content .content-img-1 {
  grid-column: 1/2;
  grid-row: 1/3;
}

.about-us-content.v1 .about-left-content .content-img-1 img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}

.about-us-content.v1 .about-left-content .content-img-2 {
  grid-column: 2/3;
  grid-row: 2/4;
}

.about-us-content.v1 .about-left-content .content-img-2 img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}

.about-us-content.v1 .about-left-content .content-box-1,
.about-us-content.v1 .about-left-content .content-box-2,
.about-us-content.v1 .about-left-content .content-img-1 {
  margin-bottom: 20px;
}

.about-us-content.v1 .about-left-content .content-box-1,
.about-us-content.v1 .about-left-content .content-box-2 {
  background: var(--color-primary);
  color: var(--color-white);
  padding: 44px 28px 28px 28px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.about-us-content.v1 .about-left-content .content-box-1 .box-icon,
.about-us-content.v1 .about-left-content .content-box-2 .box-icon {
  font-size: 60px;
}

.about-us-content.v1 .about-left-content .content-box-1 .title,
.about-us-content.v1 .about-left-content .content-box-2 .title {
  font-size: 17px;
  line-height: 27px;
  margin-top: 23px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-white);
}

.about-us-content.v1 .about-left-content .content-box-1:hover,
.about-us-content.v1 .about-left-content .content-box-2:hover {
  background: var(--color-black);
}

.about-us-content.v1 .about-left-content .content-box-1 {
  grid-column: 2/3;
  grid-row: 1/2;
}

.about-us-content.v1 .about-left-content .content-box-2 {
  grid-column: 1/2;
  grid-row: 3/4;
}

.about-us-content.v1 .about-right-content {
  margin-top: 60px;
}

.about-us-content.v2 .about-us-left-content .left-img img {
  width: 100%;
}

.about-us-content.v2 .about-us-right-content {
  margin-top: 60px;
}

.about-us-content.v2 .about-us-right-content .progress-bar {
  margin-bottom: 47px;
}

.about-us-content.v3 .section-title {
  margin-bottom: 37px;
}

.about-us-content.v3 .para {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--color-paragraph);
}

.about-us-content.v3 .about-us-left-content img {
  width: 100%;
}

/*-------------------
       Founder Ceo
   -------------------*/
.founder-ceo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  background: #f2f7f9;
  padding: 20px;
  border-radius: 20px;
  margin: 36px 0;
  border: 0.5px solid rgba(189, 207, 216, 0.44);
  position: relative;
  z-index: 1;
}

.founder-ceo .founder-img img {
  border-radius: 10px;
  height: 121px;
  width: 139px;
}

.founder-ceo .founder-data .title {
  color: var(--color-primary);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 8px;
}

.founder-ceo .founder-data .title span {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.founder-ceo .ceo-sign-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.about-us.v1 {
  position: relative;
  z-index: 1;
}

.about-us.v1 .left-vector-img {
  position: absolute;
  bottom: -118px;
  left: 0;
  z-index: -1;
}

.about-us.v1 .right-vector-img {
  position: absolute;
  right: 0;
  bottom: -120px;
  z-index: -2;
}

.about-us.v2 {
  background: #f2f7f9;
  position: relative;
  z-index: 1;
}

.about-us.v2 .bg-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.about-us.v2 .section-title {
  margin-bottom: 37px;
}

/*-------------------
       Courses Plan
    -------------------*/
.courses-plan.v1 {
  position: relative;
  z-index: 1;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(4.56%, #08132a), color-stop(13.87%, #09142a), color-stop(90.96%, #1b2e2c));
  background: linear-gradient(180deg, #08132a 4.56%, #09142a 13.87%, #1b2e2c 90.96%);
}

.courses-plan .courses-bg-left {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.courses-plan .courses-bg-right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/*------------------------
    Courses Cards Content
  ------------------------*/
.courses-cards-content {
  border-radius: 15px;
  overflow: hidden;
  background: var(--color-white);
  -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}

.courses-cards-content:hover .courses-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.courses-cards-content .courses-img {
  overflow: hidden;
  position: relative;
}

.courses-cards-content .courses-img a {
  display: inline;
}

.courses-cards-content .courses-img a img {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  width: 100%;
}

.courses-cards-content .courses-img .category {
  position: absolute;
  left: 30px;
  bottom: 20px;
  padding: 4px 16px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 2px;
}

.courses-cards-content .courses-content {
  padding: 23px 30px 20px 30px;
  border-bottom: 1px solid var(--color-border);
}

.courses-cards-content .courses-content .price-and-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-cards-content .courses-content .price-and-review .price {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-black);
}

.courses-cards-content .courses-content .price-and-review .review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.courses-cards-content .courses-content .price-and-review .review .rating {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-primary);
}

.courses-cards-content .courses-content .price-and-review .review .review-text {
  font-family: var(--body-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-paragraph);
}

.courses-cards-content .courses-content .courses-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
}

.courses-cards-content .courses-content .time-leson {
  margin-top: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.courses-cards-content .courses-content .time-leson .time,
.courses-cards-content .courses-content .time-leson .leson {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.courses-cards-content .courses-content .time-leson .time .time-icon,
.courses-cards-content .courses-content .time-leson .time .leson-icon,
.courses-cards-content .courses-content .time-leson .leson .time-icon,
.courses-cards-content .courses-content .time-leson .leson .leson-icon {
  color: var(--color-primary);
  line-height: 0;
}

.courses-cards-content .admin {
  padding: 23px 25px 30px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.courses-cards-content .admin .admin-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
}

.courses-cards-content .admin .admin-data .admin-img img {
  width: 42px;
  height: 42px;
}

.courses-cards-content .admin .admin-data .admin-name {
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-primary);
}

/*-------------------------
    Courses Grid List Cards
  -------------------------*/
.courses-cards-list-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
  border-radius: 15px;
  overflow: hidden;
  -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 24px;
}

.courses-cards-list-content:last-child {
  margin-bottom: 0;
}

.courses-cards-list-content .courses-img {
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}

.courses-cards-list-content .courses-img img {
  overflow: hidden;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.courses-cards-list-content .courses-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.courses-cards-list-content .courses-img .category {
  position: absolute;
  top: 20px;
  left: 9px;
  color: var(--color-white);
  padding: 0px 20px;
  border-radius: 100px;
  border: 1px solid rgba(237, 237, 237, 0.89);
  background: var(--color-primary);
  -webkit-box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  text-transform: uppercase;
}

.courses-cards-list-content .courses-content .price-and-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-cards-list-content .courses-content .price-and-review .price {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-primary);
}

.courses-cards-list-content .courses-content .price-and-review .price .sub-review {
  color: var(--color-paragraph);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.courses-cards-list-content .courses-content .price-and-review .review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.courses-cards-list-content .courses-content .price-and-review .review .rating {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-primary);
}

.courses-cards-list-content .courses-content .price-and-review .review .review-text {
  font-family: var(--body-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-paragraph);
}

.courses-cards-list-content .courses-content .courses-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
}

.courses-cards-list-content .admin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 18px;
  border-top: 1px dotted var(--color-border);
  margin-top: 23px;
}

.courses-cards-list-content .admin .admin-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
}

.courses-cards-list-content .admin .admin-data .admin-img img {
  width: 42px;
  height: 42px;
}

.courses-cards-list-content .admin .admin-data .admin-name {
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-primary);
}

.courses-cards-list-content .admin .lesson {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-cards-list-content .admin .lesson span {
  color: var(--color-primary);
}

/*---------------------
    Courses plan card
  ---------------------*/
.courses-plan-card {
  border-radius: 15px;
  background: var(--color-white);
  -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.courses-plan-card:hover .card-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.courses-plan-card .card-img {
  position: relative;
  overflow: hidden;
}

.courses-plan-card .card-img img {
  width: 100%;
  max-height: 286px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.courses-plan-card .card-img .category {
  position: absolute;
  bottom: 6px;
  left: 30px;
  background: var(--color-primary);
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  padding: 3px 16px;
  border-radius: 2px;
}

.courses-plan-card .courses-card-content {
  padding: 40px 30px 30px 30px;
}

.courses-plan-card .courses-card-content .admin-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.courses-plan-card .courses-card-content .admin-data .admin-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
}

.courses-plan-card .courses-card-content .admin-data .admin-content .admin-img img {
  width: 42px;
  height: 42px;
}

.courses-plan-card .courses-card-content .admin-data .admin-content .admin-title {
  color: var(--color-primary);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.courses-plan-card .courses-card-content .admin-data .lesson {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  color: var(--color-primary);
  font-size: 16px;
}

.courses-plan-card .courses-card-content .admin-data .lesson .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-plan-card .courses-card-content .title {
  color: var(--color-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  border-bottom: 1px dotted var(--color-border);
  padding-bottom: 23px;
}

.courses-plan-card .courses-card-content .price-and-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 21px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.courses-plan-card .courses-card-content .price-and-review .price {
  color: var(--color-primary);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.courses-plan-card .courses-card-content .price-and-review .price span {
  color: var(--color-paragraph);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.courses-plan-card .courses-card-content .price-and-review .review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 7px;
}

.courses-plan-card .courses-card-content .price-and-review .review .rating {
  color: var(--color-primary);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.courses-plan-card .courses-card-content .price-and-review .review .review-text {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.courses-plan-card .courses-card-content .price-and-review .review .star li {
  font-size: 12px;
}

/*-------------------
       Courses Cards
    -------------------*/
.courses-cards.v1 .nav-link {
  color: var(--color-white);
}

.courses-cards.v1 .nav-link.active {
  background: none;
}

.courses-cards.v1 .nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  padding-bottom: 25px;
  border: none;
  position: relative;
  margin-bottom: 60px;
}

.courses-cards.v1 .nav-tabs::before {
  width: 86%;
  margin: 0 auto;
  height: 3px;
  background: var(--color-white);
  content: "";
  position: absolute;
  bottom: 0;
}

.courses-cards.v1 .nav-tabs .nav-link {
  border: none;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.courses-cards.v1 .nav-tabs .nav-link.active {
  color: var(--color-primary);
  background: none;
  border: none;
  position: relative;
}

.courses-cards.v1 .nav-tabs .nav-link.active::before {
  display: none;
  content: "";
  position: absolute;
  bottom: -24px;
  left: 0;
  background: var(--color-primary);
  width: 100%;
  height: 3px;
}

.courses-cards.v1 .nav-tabs .nav-link:hover {
  border: none;
  color: var(--color-primary);
}

.courses-cards.v2 {
  margin-bottom: 70px;
}

.courses-cards.v2 .nav {
  margin-top: 70px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
}

.courses-cards.v2 .nav.nav-tabs {
  border-bottom: none;
}

.courses-cards.v2 .nav .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  border: 1px solid #e0e7f2;
  border-radius: 10px;
}

.courses-cards.v2 .nav .nav-link .nav-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 48px;
  height: 45px;
  border-radius: 5px;
  background: #294856;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: var(--color-white);
  font-size: 20px;
}

.courses-cards.v2 .nav .nav-link .title {
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: var(--title-font);
}

.courses-cards.v2 .nav .nav-link.active .nav-icon {
  background: var(--color-primary);
  color: var(--color-white);
}

.courses-cards.v2 .nav .nav-link.active .title {
  color: var(--color-primary);
}

.courses-cards.v2 .tab-content {
  margin-top: 60px;
}

.courses-cards.v2 .explore-btn {
  margin-top: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-cards.v3 {
  position: relative;
}

.courses-cards.v3 .courses-card {
  background: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.courses-cards.v3 .courses-card .user-img img {
  border-radius: 10px;
}

.courses-cards.v3 .courses-card:hover {
  background: #35454b;
}

.courses-cards.v3 .courses-card .courses-card-content .title {
  color: var(--color-white);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 30px;
}

.courses-cards.v3 .courses-card .courses-card-content a {
  color: var(--color-white);
}

.courses-cards.v3 .courses-card .courses-card-content .btn-p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  color: var(--color-white);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}

.courses-cards.v3 .courses-card .courses-card-content .btn-p:hover {
  color: var(--color-primary);
}

.courses-cards.v3 .swiper-button-next,
.courses-cards.v3 .swiper-button-prev {
  display: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.courses-cards.v3 .swiper-button-next:hover,
.courses-cards.v3 .swiper-button-prev:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.courses-cards.v3 .swiper-button-next {
  bottom: -90px;
  background: rgba(23, 155, 215, 0.1);
  width: 9%;
  border-radius: 100px;
  top: unset !important;
  right: 40%;
}

.courses-cards.v3 .swiper-button-next::after {
  font-family: "icomoon";
  content: "";
  font-size: 14px;
  margin-left: 10px;
}

.courses-cards.v3 .swiper-button-prev {
  bottom: -90px;
  background: rgba(23, 155, 215, 0.1);
  width: 9%;
  border-radius: 100px;
  right: 50%;
  left: inherit;
  top: unset !important;
}

.courses-cards.v3 .swiper-button-prev::before {
  font-family: "icomoon";
  content: "";
  font-size: 14px;
  margin-right: 10px;
}

.courses-cards.v3 .swiper-button-prev::after {
  display: none;
}

/*-------------------
       Courses Plan
  -------------------*/
.courses-plan.v3 {
  padding: 120px 0;
}

.courses-plan.v3 .courses-cards {
  margin-top: 60px;
}

.courses-plan.v3 .courses-cards .courses-cards-content {
  overflow: hidden;
  background: var(--color-white);
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}

.courses-plan.v3 .courses-cards .courses-cards-content:hover .courses-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-img {
  overflow: hidden;
  position: relative;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-img a {
  display: inline;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-img a img {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  width: 100%;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-img .category {
  position: absolute;
  left: 30px;
  bottom: 20px;
  padding: 10px 16px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 2px;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content {
  padding: 25px 30px 20px 30px;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .price-and-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .price-and-review .price {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-black);
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .price-and-review .review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .price-and-review .review .rating {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-primary);
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .price-and-review .review .review-text {
  font-family: var(--body-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-paragraph);
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .courses-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 8px;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .time-leson {
  margin-top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .time-leson .time,
.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .time-leson .leson {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .time-leson .time span,
.courses-plan.v3 .courses-cards .courses-cards-content .courses-content .time-leson .leson span {
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-primary);
}

.courses-plan.v3 .courses-cards .courses-cards-content .admin {
  padding: 25px 25px 30px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid rgba(123, 126, 134, 0.2);
}

.courses-plan.v3 .courses-cards .courses-cards-content .admin .admin-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
}

.courses-plan.v3 .courses-cards .courses-cards-content .admin .admin-data .admin-img img {
  width: 42px;
  height: 42px;
}

.courses-plan.v3 .courses-cards .courses-cards-content .admin .admin-data .admin-name {
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-primary);
}

/*-------------------
       How It Work
  -------------------*/
.how-it-work.v1 {
  background-image: url("../img/how-it-work/bg-Image.jpg");
  background-position: center;
  background-size: cover;
}

/*--------------------------
  How It Work Content
  -----------------------*/
.how-it-work-content.v1 {
  gap: 20px;
  padding: 0;
  position: relative;
  z-index: 1;
}

.how-it-work-content.v1 .middle-img-circle,
.how-it-work-content.v1 .middle-img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.how-it-work-content.v1 .work-content-1,
.how-it-work-content.v1 .work-content-3 {
  position: relative;
  padding: 30px 30px;
}

.how-it-work-content.v1 .work-content-1 .work-icon,
.how-it-work-content.v1 .work-content-3 .work-icon {
  display: none;
  position: absolute;
  top: 50%;
  right: -65px;
  width: 66px;
  height: 66px;
  background: var(--color-primary);
  -webkit-transform: translate(-50%, -50%) skew(-8deg);
  transform: translate(-50%, -50%) skew(-8deg);
  border-radius: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 40px;
  color: var(--color-white);
}

.how-it-work-content.v1 .work-content-2,
.how-it-work-content.v1 .work-content-4 {
  position: relative;
  padding: 30px 30px;
}

.how-it-work-content.v1 .work-content-2 .work-icon,
.how-it-work-content.v1 .work-content-4 .work-icon {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 66px;
  height: 66px;
  background: var(--color-primary);
  -webkit-transform: translate(-50%, -50%) skew(-8deg);
  transform: translate(-50%, -50%) skew(-8deg);
  border-radius: 10px;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 40px;
  color: var(--color-white);
}

.how-it-work-content.v1 .work-content-1,
.how-it-work-content.v1 .work-content-2,
.how-it-work-content.v1 .work-content-3,
.how-it-work-content.v1 .work-content-4 {
  margin-bottom: 20px;
}

.how-it-work-content.v1 .work-content-1,
.how-it-work-content.v1 .work-content-2,
.how-it-work-content.v1 .work-content-3,
.how-it-work-content.v1 .work-content-4 {
  border-radius: 25px;
  border-bottom: 2px solid var(--color-primary);
  -webkit-box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: var(--color-white);
}

.how-it-work-content.v1 .work-content-1 .work-title,
.how-it-work-content.v1 .work-content-2 .work-title,
.how-it-work-content.v1 .work-content-3 .work-title,
.how-it-work-content.v1 .work-content-4 .work-title {
  font-weight: 500;
  font-size: 23px;
  line-height: 33px;
  color: var(--color-black);
}

.how-it-work-content.v1 .work-content-1 .work-para,
.how-it-work-content.v1 .work-content-2 .work-para,
.how-it-work-content.v1 .work-content-3 .work-para,
.how-it-work-content.v1 .work-content-4 .work-para {
  margin-top: 5px;
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-paragraph);
}

.how-it-work-content.v1 .work-content-1:hover,
.how-it-work-content.v1 .work-content-2:hover,
.how-it-work-content.v1 .work-content-3:hover,
.how-it-work-content.v1 .work-content-4:hover {
  background: var(--color-primary);
}

.how-it-work-content.v1 .work-content-1:hover .work-title,
.how-it-work-content.v1 .work-content-1:hover .work-para,
.how-it-work-content.v1 .work-content-2:hover .work-title,
.how-it-work-content.v1 .work-content-2:hover .work-para,
.how-it-work-content.v1 .work-content-3:hover .work-title,
.how-it-work-content.v1 .work-content-3:hover .work-para,
.how-it-work-content.v1 .work-content-4:hover .work-title,
.how-it-work-content.v1 .work-content-4:hover .work-para {
  color: var(--color-white);
}

.how-it-work-content.v1 .work-content-1:hover .work-icon,
.how-it-work-content.v1 .work-content-2:hover .work-icon,
.how-it-work-content.v1 .work-content-3:hover .work-icon,
.how-it-work-content.v1 .work-content-4:hover .work-icon {
  border: 1px solid var(--color-white);
}

.how-it-work-content.v1 .work-content-1,
.how-it-work-content.v1 .work-content-3 {
  grid-column: 1/5;
}

.how-it-work-content.v1 .work-content-2,
.how-it-work-content.v1 .work-content-4 {
  grid-column: 9/13;
}

.how-it-work-content.v2 .how-it-work-left-content .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 47px;
}

.how-it-work-content.v2 .right-img {
  margin-top: 50px;
}

.how-it-work-content.v2 .left-img {
  margin-bottom: 43px;
}

.how-it-work-content.v2 .right-img {
  position: relative;
}

.how-it-work-content.v2 .right-img .right-img-bg {
  position: absolute;
  top: 0;
  left: 0;
}

.how-it-work-content.v2 .right-img,
.how-it-work-content.v2 .left-img {
  border-radius: 20px;
  overflow: hidden;
}

.how-it-work-content.v2 .right-img img,
.how-it-work-content.v2 .left-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.how-it-work-content.v2 .right-img:hover img,
.how-it-work-content.v2 .left-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.how-it-work-content.v2 .how-it-work-right-content {
  margin-top: 60px;
}

.how-it-work-content.v3 .how-it-work-right-content {
  display: grid;
  gap: 24px;
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-one,
.how-it-work-content.v3 .how-it-work-right-content .right-content-two,
.how-it-work-content.v3 .how-it-work-right-content .right-content-three {
  border-radius: 20px;
  overflow: hidden;
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-one img,
.how-it-work-content.v3 .how-it-work-right-content .right-content-two img,
.how-it-work-content.v3 .how-it-work-right-content .right-content-three img {
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-one:hover img,
.how-it-work-content.v3 .how-it-work-right-content .right-content-two:hover img,
.how-it-work-content.v3 .how-it-work-right-content .right-content-three:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-one {
  grid-column: 1/4;
  grid-row: 1/4;
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-two {
  grid-column: 4/6;
  grid-row: 1/3;
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-two img {
  border-radius: 10px;
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-three {
  grid-column: 4/6;
  grid-row: 3/4;
}

.how-it-work-content.v3 .how-it-work-right-content .right-content-three img {
  border-radius: 10px;
}

.how-it-work-content.v3 .progress-bar {
  margin-bottom: 50px;
}

.how-it-work.v2 {
  position: relative;
  z-index: 1;
  background-image: url("../../assets/img/how-it-work/v2/bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.how-it-work.v2 .bg-vector-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.how-it-work.v3 {
  background: #f2f7f9;
}

/*-------------------
       CTA
  -------------------*/
.cta.v1 {
  background-image: url("../../assets/img/cta/cta-img.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.cta.v1 .cta-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cta.v1 .cta-title .title-para {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 12px;
}

.cta.v1 .cta-title .big-title {
  color: var(--color-white);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

.cta.v1 .cta-bg-right-img {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.cta.v1 .cta-bg-left-img {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.cta.v2 .cta-left-content {
  position: relative;
}

.cta.v2 .cta-left-content .cta-big-img {
  height: 100%;
}

.cta.v2 .cta-left-content .cta-big-img img {
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cta.v2 .cta-left-content .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  border: 8px solid rgba(255, 255, 255, 0.52);
  font-size: 25px;
  color: var(--color-primary);
}

.cta.v2 .cta-right-content {
  padding: 30px;
  background: rgba(23, 155, 215, 0.96);
  position: relative;
  z-index: 1;
}

.cta.v2 .cta-right-content .section-title {
  margin-bottom: 50px;
}

.cta.v2 .cta-right-content .cta-bg-img {
  position: absolute;
  right: 7px;
  bottom: 35px;
  z-index: -1;
}

.cta.v2 .section-title .title-para {
  margin-top: 30px;
}

/*-------------------
      Why Choose Us
  -------------------*/
.why-choose-us.v1 {
  position: relative;
  z-index: 1;
}

.why-choose-us.v1 .why-choose-us-background-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.why-choose-us.v1 .choose-us-right-content {
  margin-top: 60px;
}

.why-choose-us.v1 .choose-us-right-content .section-title {
  margin-bottom: 25px;
}

.why-choose-us.v2 {
  background-image: url("../../assets/img/why-choose-us/v2/Way-choses-Us.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.why-choose-us.v3 {
  background-image: url("../../assets/img/why-choose-us/v3/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.why-choose-us.v3 .section-title {
  margin-bottom: 21px;
}

/*-------------------
      Choose Us Content
  -------------------*/
.choose-us-content .choose-us-left-content {
  position: relative;
}

.choose-us-content .choose-us-left-content .choose-us-big-img {
  border-radius: 20px;
  overflow: hidden;
}

.choose-us-content .choose-us-left-content .choose-us-big-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.choose-us-content .choose-us-left-content .choose-us-big-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.choose-us-content .choose-us-left-content .choose-us-small-img {
  background-image: url("../../assets/img/why-choose-us/small-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 80px;
  left: 0;
  border: 5px solid var(--color-white);
  border-radius: 20px;
  padding: 5px;
}

.choose-us-content .choose-us-left-content .choose-us-small-img img {
  width: 100%;
  border-radius: 20px;
}

.choose-us-content .choose-us-left-content .choose-us-small-img .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 74px;
  height: 74px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1;
  -webkit-animation: play-icon 2.4s ease-out infinite;
  animation: play-icon 2.4s ease-out infinite;
}

@-webkit-keyframes play-icon {
  100% {
    background: rgba(218, 218, 218, 0.38);
  }
}

@keyframes play-icon {
  100% {
    background: rgba(218, 218, 218, 0.38);
  }
}

.choose-us-content .choose-us-left-content .choose-us-small-img .play-icon::before {
  position: absolute;
  content: "";
  background: var(--color-white);
  width: 54px;
  height: 54px;
  z-index: -2;
  border-radius: 50%;
}

.choose-us-content .choose-us-left-content .online-support {
  display: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 43px;
  position: absolute;
  right: -7px;
  bottom: -43px;
  background: var(--color-primary);
  padding: 19px 28px;
  border-radius: 20px;
}

.choose-us-content .choose-us-left-content .online-support .support-icon {
  font-size: 68px;
  color: var(--color-white);
}

.choose-us-content .choose-us-left-content .online-support .support-content {
  position: relative;
}

.choose-us-content .choose-us-left-content .online-support .support-content ::before {
  position: absolute;
  top: 11px;
  left: -22px;
  content: "";
  width: 1px;
  height: 80px;
  background: rgba(255, 255, 255, 0.21);
}

.choose-us-content .choose-us-left-content .online-support .support-content .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-white);
  margin-bottom: 3px;
}

.choose-us-content .choose-us-left-content .online-support .support-content .support-number {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--color-white);
  margin-bottom: 12px;
}

/*-----------------------
     Choose Skill items
  -----------------------*/
.chooses-skill-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 52px;
}

.chooses-skill-items li {
  text-align: center;
  padding: 30px 28px;
  border-radius: 5px;
  background: var(--color-white);
  width: 140px;
  max-width: 170px;
}

.chooses-skill-items li .skill-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 35px 0px rgba(35, 31, 80, 0.13);
  box-shadow: 0px 10px 35px 0px rgba(35, 31, 80, 0.13);
  border-radius: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 30px;
  color: var(--color-primary);
}

.chooses-skill-items li .title {
  color: var(--color-primary);
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 11px;
}

.chooses-skill-items li .para {
  color: var(--color-paragraph);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3px;
}

.chooses-skill-items li:hover .skill-icon {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

/*-------------------
     Skill Items
  -------------------*/
.skill-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
}

.skill-items .skill-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 25px;
}

.skill-items .skill-item .item-icon {
  width: 90px;
  height: 90px;
  border-radius: 100px;
  border: 1px dashed #fafafa;
  background: rgba(46, 71, 96, 0.14);
  -webkit-backdrop-filter: blur(6.5px);
  backdrop-filter: blur(6.5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 43px;
  color: var(--color-white);
}

.skill-items .skill-item .title {
  font-size: 44px;
  line-height: 54px;
  font-weight: 600;
  color: var(--color-white);
  text-transform: uppercase;
}

.skill-items .skill-item .para {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-white);
  text-transform: uppercase;
  font-family: var(--title-font);
}

/*-------------------
     Star
  -------------------*/
.star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.star li {
  font-size: 12px;
  color: #fec107;
}

.star li.color-blue {
  color: var(--color-primary);
}

.star li.color-grey {
  color: #bfbfbf;
}

/*-------------------
      My Accordion
  -------------------*/
.my-accordion {
  padding: 0;
  margin: 25px 0 0 0;
}

.my-accordion .item {
  margin-bottom: 20px;
  padding: 22px 30px;
  max-height: 300px;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid var(--color-white);
}

.my-accordion .item:last-child {
  margin-bottom: 0;
}

.my-accordion .item .accordion-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding-bottom: 17px;
  background: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-primary);
}

.my-accordion .item .accordion-btn span {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-primary);
  display: block;
  width: 34px;
  height: 34px;
  background: var(--color-white5);
  border-radius: 20px;
  margin-right: 20px;
}

.my-accordion .item .accordion-btn::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  background: var(--color-border);
  width: 100%;
  height: 1px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.my-accordion .item .accordion-btn::after {
  font-family: "icomoon";
  content: "";
  background: var(--color-white5);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.my-accordion .item .hidden-box {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.my-accordion .item .hidden-box .box-para {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-paragraph);
}

.my-accordion .active {
  border-bottom: none;
}

.my-accordion .active.item {
  border: 1px solid var(--color-border);
  border-radius: 10px;
}

.my-accordion .active.item::before {
  display: none;
}

.my-accordion .active .accordion-btn::before {
  display: none;
}

.my-accordion .active .accordion-btn::after {
  font-family: "icomoon";
  content: "";
  font-size: 16px;
}

.my-accordion .active .hidden-box {
  max-height: 300px;
}

/*-------------------
    Choose Us Slide
  -------------------*/
.choose-us-slide {
  margin-top: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 78px;
  -webkit-animation: 15s add-slide infinite linear;
  animation: 15s add-slide infinite linear;
  white-space: nowrap;
}

.choose-us-slide .choose-us-slide-text {
  font-weight: 500;
  font-size: 160px;
  line-height: 170px;
  -webkit-text-stroke: 0.1vw var(--color-primary);
  color: var(--color-white);
  text-transform: uppercase;
}

.choose-us-slide .choose-us-slide-text.v1 {
  color: var(--color-primary);
}

.choose-us-slide .choose-us-slide-text.v2 {
  -webkit-text-stroke: 0.1vw var(--color-black);
  stroke: none;
  color: var(--color-black);
}

@-webkit-keyframes add-slide {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes add-slide {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/*-------------------------
  Student Feedback Content
  --------------------------*/
.student-feedback-content .feedback-left-content {
  padding-right: 28px;
  position: relative;
  z-index: 1;
}

.student-feedback-content .feedback-left-content .feedback-left-img {
  overflow: hidden;
  border-radius: 10px;
}

.student-feedback-content .feedback-left-content .feedback-left-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.student-feedback-content .feedback-left-content .feedback-left-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.student-feedback-content .feedback-left-content .feedback-right-bg-img {
  position: absolute;
  bottom: 0;
  left: -251px;
  z-index: -1;
}

.student-feedback-content .feedback-right-content {
  margin-top: 60px;
  position: relative;
  z-index: 1;
}

.student-feedback-content .feedback-right-content .student-feedback-info .student-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.student-feedback-content .feedback-right-content .student-feedback-info .student-profile .student-img img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  object-fit: cover;
}

.student-feedback-content .feedback-right-content .student-feedback-info .student-profile .student-content .content-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--color-primary);
}

.student-feedback-content .feedback-right-content .student-feedback-info .student-profile .student-content .content-para {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--color-paragraph);
  margin-top: 3px;
  font-family: var(--title-font);
}

.student-feedback-content .feedback-right-content .student-feedback-info .info-para {
  margin-top: 26px;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-paragraph);
}

.student-feedback-content .feedback-right-content .student-feedback-info .star {
  margin-top: 41px;
}

.student-feedback-content .feedback-right-content .student-feedback-info .star li {
  font-size: 18px;
  line-height: 28px;
}

.student-feedback-content .feedback-right-content .feedback-right-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.student-feedback-content .feedback-right-content .quat-big-icon {
  position: absolute;
  top: -80px;
  right: 0;
  color: rgba(191, 194, 195, 0.15);
  font-size: 120px;
  font-weight: 900;
  line-height: 250px;
}

/*-------------------
  Student Cards
  -------------------*/
.student-cards {
  position: relative;
  margin-top: 70px;
  z-index: 1;
}

.student-cards .student-card .title {
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.student-cards .student-card .title span {
  color: var(--color-primary);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.student-cards .student-card .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 15px;
}

.student-cards .student-card .star li {
  font-size: 18px;
}

.student-cards .student-card .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
  margin-top: 15px;
}

.student-cards .student-card .social li a {
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(23, 155, 215, 0.1);
  color: var(--color-primary);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.student-cards .student-card .social li a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.student-cards .student-card .student-card-icon {
  position: absolute;
  top: 30px;
  right: 28px;
  color: var(--color-primary);
  font-size: 44px;
  line-height: 70px;
  font-weight: 900;
  z-index: -1;
}

.student-cards .swiper-button-next,
.student-cards .swiper-button-prev {
  display: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.student-cards .swiper-button-next:hover,
.student-cards .swiper-button-prev:hover {
  background: rgba(83, 81, 81, 0.4);
  color: var(--color-white);
}

.student-cards .swiper-button-next {
  top: -93px;
  background: var(--color-white);
  width: 9%;
  border-radius: 100px;
}

.student-cards .swiper-button-next::after {
  font-family: "icomoon";
  content: "";
  font-size: 14px;
  margin-left: 10px;
}

.student-cards .swiper-button-prev {
  top: -93px;
  background: var(--color-white);
  width: 9%;
  border-radius: 100px;
  right: 135px;
  left: inherit;
}

.student-cards .swiper-button-prev::before {
  font-family: "icomoon";
  content: "";
  font-size: 14px;
  margin-right: 10px;
}

.student-cards .swiper-button-prev::after {
  display: none;
}

.student-cards .student-card {
  position: relative;
  border-radius: 20px;
  border: 1px solid #e3e3e3;
  padding: 20px;
  background: var(--color-white);
  z-index: 1;
}

.student-cards .student-card .user-img {
  position: absolute;
  left: -64px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: none;
}

.student-cards .student-card .user-img img {
  border-radius: 10px;
}

/*-------------------
  Student Feedback
  -------------------*/
.student-feedback.v2 {
  background: url("../../assets/img/student-feedback/v2/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/*------------------------
  My Swiper Student Slider
  ------------------------*/
.my-swiper-student-slider {
  position: relative;
}

.my-swiper-student-slider .swiper-button-next {
  display: none;
  right: calc(50% - 60px);
}

.my-swiper-student-slider .swiper-button-next::after {
  content: ">";
  /* font-family: "icomoon"; */
}

.my-swiper-student-slider .swiper-button-prev {
  display: none;
  left: calc(50% - 60px);
}

.my-swiper-student-slider .swiper-button-prev::after {
  content: "<";
  /* font-family: "icomoon"; */
}

.my-swiper-student-slider .swiper-button-next,
.my-swiper-student-slider .swiper-button-prev {
  position: absolute;
  bottom: 0;
  top: unset !important;
  border-radius: 5px;
}

.my-swiper-student-slider .swiper-button-next::after,
.my-swiper-student-slider .swiper-button-prev::after {
  font-weight: 100;
  font-size: 26px;
  line-height: 36px;
  border-radius: 50%;
  background: rgba(23, 155, 215, 0.1);
  padding: 4px 10px;
  color: var(--color-primary);
}

/*-------------------
    Get Free Content
  -------------------*/
.get-free-content {
  border-radius: 20px;
  background: var(--color-white5);
  background-image: url("../../assets/img/get-free-now/bg-map.png");
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
}

.get-free-content .get-free-left-content {
  display: none;
  padding: 11px 0 23px 105px;
}

.get-free-content .get-free-left-content img {
  width: 100%;
}

.get-free-form-content .form-box,
.get-free-form-content .form-box-last {
  border: 1px solid #e2e5e7;
  border-radius: 10px;
  overflow: hidden;
  background: var(--color-white);
}

.get-free-form-content .group-form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.get-free-form-content .group-form-box .form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.get-free-form-content .group-form-box .form-box input {
  width: 100%;
}

.get-free-form-content .group-form-box .form-box input::-webkit-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .group-form-box .form-box input::-moz-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .group-form-box .form-box input:-ms-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .group-form-box .form-box input::-ms-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .group-form-box .form-box input::placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 20px 20px 20px 20px;
}

.get-free-form-content .form-box input {
  width: 100%;
  max-height: 60px;
}

.get-free-form-content .form-box input::-webkit-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.get-free-form-content .form-box input::-moz-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.get-free-form-content .form-box input:-ms-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.get-free-form-content .form-box input::-ms-input-placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.get-free-form-content .form-box input::placeholder {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
}

.get-free-form-content .form-box-last {
  margin-top: 20px;
}

.get-free-form-content .form-box-last textarea {
  height: 170px;
  resize: none;
  width: 100%;
  padding: 20px;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .form-box-last textarea::-webkit-input-placeholder {
  font-family: var(--body-font);
  color: #7B7E86;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .form-box-last textarea::-moz-placeholder {
  font-family: var(--body-font);
  color: #7B7E86;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .form-box-last textarea:-ms-input-placeholder {
  font-family: var(--body-font);
  color: #7B7E86;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .form-box-last textarea::-ms-input-placeholder {
  font-family: var(--body-font);
  color: #7B7E86;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .form-box-last textarea::placeholder {
  font-family: var(--body-font);
  color: #7B7E86;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.get-free-form-content .check-box {
  margin-top: 30px;
}

.get-free-form-content .check-box button {
  width: 100%;
  padding: 15px 40px;
}

/*-------------------
    Clock Count
  -------------------*/
.getting-started {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 50px;
  gap: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.getting-started .single-countdown {
  padding: 5px 0;
  text-align: center;
  color: var(--color-primary);
  background: var(--color-white);
  width: 113px;
  height: 113px;
  border: 4px solid var(--color-primary);
  border-radius: 10px;
}

.getting-started .single-countdown .count-title {
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  color: var(--color-primary);
}

.getting-started .single-countdown .count-para {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-paragraph);
  text-transform: capitalize;
  font-family: var(--title-font);
}

.getting-started.v2 {
  margin: 46px 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  gap: 20px;
}

.getting-started.v2 .single-countdown {
  border: 2px solid var(--color-primary);
}

/*-------------------
    Register Now
  -------------------*/
.register-now {
  padding-top: 120px;
  background-image: url("../../assets/img/register-now/register-now-img.jpg");
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
}

.register-now::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 893px;
  background: linear-gradient(21deg, rgba(18, 39, 114, 0) -4.08%, rgba(18, 39, 114, 0) 28.7%, #179bd7 98.87%);
}

.register-now .background-big-img {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
}

/*-------------------
    Get Free Now
  -------------------*/
.get-free-now.v2 {
  background-image: url("../../assets/img/get-free-now/v2/big-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*-------------------
  Blog Cards
  -------------------*/
.blog-cards.v1 {
  margin-top: 70px;
}

.blog-cards.v1 .card-content-1 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.blog-cards.v1 .card-content-1 .card-img-1 img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  height: 504px;
  max-height: 504px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-cards.v1 .card-content-1 .content-data {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.blog-cards.v1 .card-content-1 .content-data .admin-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}

.blog-cards.v1 .card-content-1 .content-data .admin-data .date-content,
.blog-cards.v1 .card-content-1 .content-data .admin-data .user-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  color: var(--color-white);
  margin-bottom: 11px;
}

.blog-cards.v1 .card-content-1 .content-data .admin-data .date-content .content-icon,
.blog-cards.v1 .card-content-1 .content-data .admin-data .user-content .content-icon {
  color: var(--color-primary);
  font-weight: 400;
  font-size: 15px;
}

.blog-cards.v1 .card-content-1 .content-data .admin-data .date-para,
.blog-cards.v1 .card-content-1 .content-data .admin-data .user-para {
  font-family: var(--title-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.blog-cards.v1 .card-content-1 .content-data .big-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-white);
  width: calc(100% - 20px);
}

.blog-cards.v1 .card-content-1:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog-cards.v1 .card-content-2 {
  margin-bottom: 24px;
}

.blog-cards.v1 .card-content-2,
.blog-cards.v1 .card-content-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 10px;
  overflow: hidden;
  background: var(--color-white);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: var(--color-primary);
}

.blog-cards.v1 .card-content-2 img,
.blog-cards.v1 .card-content-3 img {
  width: 100%;
}

.blog-cards.v1 .card-content-2 .content-data,
.blog-cards.v1 .card-content-3 .content-data {
  padding: 30px 30px 30px 30px;
}

.blog-cards.v1 .card-content-2 .content-data .admin-data,
.blog-cards.v1 .card-content-3 .content-data .admin-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}

.blog-cards.v1 .card-content-2 .content-data .admin-data .date-content,
.blog-cards.v1 .card-content-2 .content-data .admin-data .user-content,
.blog-cards.v1 .card-content-3 .content-data .admin-data .date-content,
.blog-cards.v1 .card-content-3 .content-data .admin-data .user-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.blog-cards.v1 .card-content-2 .content-data .admin-data .date-content .date-para,
.blog-cards.v1 .card-content-2 .content-data .admin-data .date-content .user-para,
.blog-cards.v1 .card-content-2 .content-data .admin-data .user-content .date-para,
.blog-cards.v1 .card-content-2 .content-data .admin-data .user-content .user-para,
.blog-cards.v1 .card-content-3 .content-data .admin-data .date-content .date-para,
.blog-cards.v1 .card-content-3 .content-data .admin-data .date-content .user-para,
.blog-cards.v1 .card-content-3 .content-data .admin-data .user-content .date-para,
.blog-cards.v1 .card-content-3 .content-data .admin-data .user-content .user-para {
  font-family: var(--title-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.blog-cards.v1 .card-content-2 .content-data .big-title,
.blog-cards.v1 .card-content-3 .content-data .big-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-black);
  margin-bottom: 14px;
}

.blog-cards.v1 .card-content-2 .content-data .para,
.blog-cards.v1 .card-content-3 .content-data .para {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-paragraph);
}

.blog-cards.v1 .card-content-2 .card-img-2,
.blog-cards.v1 .card-content-2 .card-img-3,
.blog-cards.v1 .card-content-3 .card-img-2,
.blog-cards.v1 .card-content-3 .card-img-3 {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
}

.blog-cards.v1 .card-content-2 .card-img-2 img,
.blog-cards.v1 .card-content-2 .card-img-3 img,
.blog-cards.v1 .card-content-3 .card-img-2 img,
.blog-cards.v1 .card-content-3 .card-img-3 img {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-cards.v1 .card-content-2:hover img,
.blog-cards.v1 .card-content-3:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog-cards.v2 .blog-card {
  position: relative;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: var(--color-white);
  overflow: hidden;
}

.blog-cards.v2 .blog-card .card-img {
  overflow: hidden;
}

.blog-cards.v2 .blog-card .card-img img {
  width: 100%;
  height: 254px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-cards.v2 .blog-card .card-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog-cards.v2 .blog-card .card-body {
  padding: 20px 30px;
}

.blog-cards.v2 .blog-card .card-body .title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 13px;
}

.blog-cards.v2 .blog-card .card-body .para {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--color-paragraph);
}

.blog-cards.v2 .blog-card .card-footer {
  border-top: 1px solid #dfdfdf;
  padding: 13px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

.blog-cards.v2 .blog-card .card-footer .all-btn .btn-p {
  color: var(--color-secondary);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.blog-cards.v2 .blog-card .card-footer .all-btn:hover .btn-p {
  color: var(--color-primary);
}

.blog-cards.v2 .blog-card .card-footer .footer-comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
}

.blog-cards.v2 .blog-card .card-footer .footer-comments .comments-icon {
  color: var(--color-primary);
}

.blog-cards.v2 .blog-card .card-footer .footer-comments a {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.blog-cards.v2 .blog-card .card-footer .footer-comments:hover a {
  color: var(--color-primary);
}

.blog-cards.v2 .blog-card .card-date {
  position: absolute;
  top: 20px;
  left: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  background: var(--color-primary);
}

.blog-cards.v2 .blog-card .card-date .date-icon {
  font-size: 12px;
  color: var(--color-white);
}

.blog-cards.v2 .blog-card .card-date .para {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/*-------------------
  Our Blog
  -------------------*/
.our-blog.v1 {
  background: linear-gradient(103.71deg, #f6f9fb 14%, rgba(181, 209, 221, 0.19) 97.69%);
}

/*------------------------
  Our Best Team Content
  ------------------------*/
.our-best-team-content.v1 {
  overflow: hidden;
}

.our-best-team-content.v1 .team-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}

.our-best-team-content.v1 .team-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.our-best-team-content.v1 .team-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.our-best-team-content.v1 .team-img .team-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 5px;
  width: 80%;
  padding: 24px;
  background: var(--color-white);
  position: absolute;
  bottom: 22px;
  right: 10%;
}

.our-best-team-content.v1 .team-img .team-content .left-data .team-title {
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
}

.our-best-team-content.v1 .team-img .team-content .left-data .team-para {
  margin-top: 3px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--color-paragraph);
  font-family: var(--title-font);
}

.our-best-team-content.v1 .team-img .team-content .team-share-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
}

.our-best-team-content.v1 .team-img .team-content .team-share-box .share {
  width: 36px;
  height: 36px;
  background: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
}

.our-best-team-content.v1 .team-img .team-content .team-share-box .share a {
  color: var(--color-white);
}

.our-best-team-content.v1 .team-img .team-content .team-share-box ul {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  top: -121px;
  right: 24px;
  border-radius: 2px;
  overflow: hidden;
  opacity: 0;
  display: block;
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
  z-index: -1;
}

.our-best-team-content.v1 .team-img .team-content .team-share-box ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 8px 0;
  width: 36px;
  height: 36px;
  background: var(--color-primary);
}

.our-best-team-content.v1 .team-img .team-content .team-share-box ul li a {
  color: var(--color-white);
}

.our-best-team-content.v1 .team-img .team-content .team-share-box ul li:hover a {
  color: var(--color-black);
}

.our-best-team-content.v1 .team-img .team-content .team-share-box ul li::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 16px;
  height: 0.5px;
  background: var(--color-white);
}

.our-best-team-content.v1 .team-img .team-content .team-share-box ul li:last-child {
  padding: 0;
}

.our-best-team-content.v1 .team-img .team-content .team-share-box ul li:last-child::before {
  display: none;
}

.our-best-team-content.v1 .team-img .team-content .team-share-box:hover {
  display: block;
}

.our-best-team-content.v1 .team-img .team-content .team-share-box:hover ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  display: block;
  z-index: 0;
}

.our-best-team-content.v2 .team-data {
  text-align: center;
}

.our-best-team-content.v2 .team-data .data-img {
  margin-bottom: 45px;
}

.our-best-team-content.v2 .team-data .data-img img {
  border: 4px solid var(--color-primary);
  padding: 8px;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.our-best-team-content.v2 .team-data .data-img .team-share-box {
  position: relative;
  max-width: 54px;
  margin: 0 auto;
}

.our-best-team-content.v2 .team-data .data-img .team-share-box:hover ul {
  opacity: 1;
  z-index: 1;
}

.our-best-team-content.v2 .team-data .data-img .team-share-box .share {
  overflow: hidden;
  position: absolute;
  top: -32px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--color-primary);
  border-radius: 50px;
  cursor: pointer;
}

.our-best-team-content.v2 .team-data .data-img .team-share-box .share>a {
  color: var(--color-white);
}

.our-best-team-content.v2 .team-data .data-img .team-share-box ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  opacity: 0;
  top: -88px;
  left: 50%;
  gap: 8px;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.our-best-team-content.v2 .team-data .data-img .team-share-box ul li a {
  width: 40px;
  height: 40px;
  background: var(--color-white);
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-primary);
}

.our-best-team-content.v2 .team-data .data-img .team-share-box ul li a:hover {
  color: var(--color-black);
}

.our-best-team-content.v2 .team-data .title {
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.our-best-team-content.v2 .team-data .para {
  color: var(--color-primary);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--title-font);
}

.our-best-team-content.v3 {
  overflow: hidden;
}

.our-best-team-content.v3 .team-img {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.our-best-team-content.v3 .team-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.our-best-team-content.v3 .team-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.our-best-team-content.v3 .team-img .team-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 5px;
  width: 100%;
  padding: 24px;
  background: var(--color-primary);
  position: absolute;
  bottom: 0;
  border-top: 2px solid var(--color-white);
  border-radius: 10px;
}

.our-best-team-content.v3 .team-img .team-content .left-data .team-title {
  color: var(--color-white);
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.our-best-team-content.v3 .team-img .team-content .left-data .team-title a {
  color: var(--color-white);
}

.our-best-team-content.v3 .team-img .team-content .left-data .team-title:hover a {
  color: var(--color-black);
}

.our-best-team-content.v3 .team-img .team-content .left-data .team-para {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--color-white);
  font-family: var(--title-font);
}

.our-best-team-content.v3 .team-img .team-content .team-share-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
}

.our-best-team-content.v3 .team-img .team-content .team-share-box .share {
  width: 36px;
  height: 36px;
  background: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid var(--color-white);
  border-radius: 100%;
}

.our-best-team-content.v3 .team-img .team-content .team-share-box .share a {
  color: var(--color-white);
}

.our-best-team-content.v3 .team-img .team-content .team-share-box ul {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: absolute;
  top: -180px;
  right: 24px;
  border-radius: 2px;
  overflow: hidden;
  opacity: 0;
  display: block;
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
  z-index: -1;
  background: var(--color-primary);
  border-radius: 10px;
  padding: 10px 0;
}

.our-best-team-content.v3 .team-img .team-content .team-share-box ul li {
  width: 36px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 0;
}

.our-best-team-content.v3 .team-img .team-content .team-share-box ul li a {
  color: var(--color-white);
}

.our-best-team-content.v3 .team-img .team-content .team-share-box ul li:last-child {
  padding: 0;
}

.our-best-team-content.v3 .team-img .team-content .team-share-box ul li:last-child::before {
  display: none;
}

.our-best-team-content.v3 .team-img .team-content .team-share-box:hover {
  display: block;
}

.our-best-team-content.v3 .team-img .team-content .team-share-box:hover ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  display: block;
  z-index: 0;
}

/*-------------------
       Our Best Team
  -------------------*/
.our-best-team.v1 {
  background: linear-gradient(213deg, rgba(22, 39, 44, 0.81) -28.68%, #08142d 59.85%);
}

/*-------------------
    Skill Section
  -------------------*/
.skill-section {
  background-image: url("../../assets/img/skill-section/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}

.skill-section::before {
  position: absolute;
  top: 0;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-223.61%, rgba(21, 37, 57, 0.4)), color-stop(-123.61%, rgba(2, 6, 18, 0.35)));
  background: linear-gradient(180deg, rgba(21, 37, 57, 0.4) -223.61%, rgba(2, 6, 18, 0.35) -123.61%);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.skill-section .skill-left-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.skill-section .skill-right-img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/*-------------------
    Make An Appointment
  -------------------*/
.make-an-appointment {
  background-image: url("../../assets/img/make-an-appointment/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
}

.make-an-appointment .appointment-left-content {
  display: none;
}

.make-an-appointment .section-title {
  margin-bottom: 46px;
}

/*-------------------
    Contact Info
  -------------------*/
.contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
}

.contact-info .contact-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 100px;
  border: 0.5px solid #e0e7f2;
  background: var(--color-white);
  -webkit-box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.12);
  position: relative;
}

.contact-info .contact-icon::after {
  content: "";
  position: absolute;
  width: 69px;
  height: 69px;
  border-radius: 100px;
  border: 1px solid #e0e7f2;
}

.contact-info .info-data {
  margin-left: 20px;
  margin-top: 10px;
}

.contact-info .info-data .para {
  color: var(--color-paragraph);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.contact-info .info-data .title {
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--title-font);
}

.contact-info.v2 .contact-icon {
  font-size: 25px;
  color: var(--color-white);
  border: none;
  background: transparent;
}

.contact-info.v2 .contact-icon::after {
  width: 55px;
  height: 55px;
}

.contact-info.v2 .para,
.contact-info.v2 .title {
  color: var(--color-white);
}

.contact-info.v2 .title {
  font-family: var(--body-font);
}

/*-------------------
    Check Box
  -------------------*/
.check-box.v1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 17px;
}

.check-box.v1 .box-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
  border-radius: 100px;
  border: 1px solid #eee;
  background: var(--color-white);
  padding: 15px 0 15px 22px;
  width: 100%;
}

.check-box.v1 .box-item .item-icon {
  font-size: 18px;
  color: var(--color-primary);
  line-height: 0;
}

.check-box.v1 .box-item .item-para {
  color: var(--color-black);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--title-font);
}

.check-box.v2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 7px 55px;
}

.check-box.v2 .box-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
  background: var(--color-white);
  width: 100%;
}

.check-box.v2 .box-item .item-icon {
  font-size: 18px;
  color: var(--color-primary);
  line-height: 0;
}

.check-box.v2 .box-item .item-para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.check-box.v3 {
  margin-top: 28px;
  margin-bottom: 31px;
}

.check-box.v3 li {
  margin-bottom: 6px;
}

.check-box.v3 li:last-child {
  margin-bottom: 0;
}

.check-box.v3 .box-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
}

.check-box.v3 .box-item .item-icon {
  font-size: 16px;
  color: var(--color-primary);
  line-height: 0;
}

/*-------------------
    Features Cards
  -------------------*/
.features-card {
  position: relative;
  width: 100%;
}

.features-card:hover .card-content {
  background: var(--color-primary);
}

.features-card:hover .card-content .title,
.features-card:hover .card-content .para {
  color: var(--color-white);
}

.features-card:hover .card-content .content-icon {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.features-card .card-img {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.features-card .card-img img {
  width: 100%;
}

.features-card .card-content {
  width: 90%;
  margin: 0 auto;
  position: absolute;
  bottom: -110px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--color-white);
  padding: 0 26px 27px 26px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0px 29px 52px 0px rgba(28, 33, 48, 0.1);
  box-shadow: 0px 29px 52px 0px rgba(28, 33, 48, 0.1);
  margin-bottom: 100px;
}

.features-card .card-content .content-icon {
  width: 108px;
  height: 108px;
  margin: -50px auto 0 auto;
  background: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  border: 4px solid var(--color-white);
  margin-bottom: 12px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 64px;
  color: var(--color-white);
}

.features-card .card-content .title,
.features-card .card-content .para {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.features-card .card-content .title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 4px;
}

.features-card .card-content .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/*-------------------
    Features
  -------------------*/
.features {
  margin-top: 80px;
}

/*-------------------
    Pricing Card
   -------------------*/
.pricing-card {
  border-radius: 15px;
  background: #f6f6f7;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.pricing-card .title {
  color: var(--color-black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

.pricing-card .title,
.pricing-card .para,
.pricing-card i,
.pricing-card .item-para,
.pricing-card .price {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.pricing-card .price {
  color: var(--color-primary);
  font-size: 72px;
  line-height: 82px;
  font-weight: 500;
}

.pricing-card .price sup {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-right: 6px;
  vertical-align: super;
  top: -4px;
}

.pricing-card .price sub {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  bottom: 0;
}

.pricing-card:hover {
  background: var(--color-primary);
}

.pricing-card:hover .title,
.pricing-card:hover .para,
.pricing-card:hover i,
.pricing-card:hover .item-para,
.pricing-card:hover .price {
  color: var(--color-white);
}

.pricing-card:hover .btn-blue {
  background: var(--color-white);
  color: var(--color-primary);
}

.pricing-card:hover .check-box.v3 .box-item span {
  color: var(--color-white);
}

.pricing-card .card-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100px;
  height: 106px;
  border-radius: 10px;
  background: var(--color-primary);
  border: 4px solid var(--color-white);
  margin-left: 40px;
  -webkit-transform: translateY(-49px);
  transform: translateY(-49px);
  margin-top: 49px;
  font-size: 58px;
  color: var(--color-white);
}

.pricing-card .wave-img img {
  width: 100%;
}

.pricing-card .pricing-top-content {
  padding: 0px 0 12px 40px;
}

.pricing-card .pricing-bottom-content {
  padding: 14px 36px 28px 40px;
}

/*-------------------
    Pricing Plan
   -------------------*/
.pricing-plan {
  position: relative;
}

.pricing-plan .nav-pills {
  border: 1px solid #dfdfdf;
  padding: 6px 9px;
  border-radius: 100px;
}

.pricing-plan .section-title {
  margin-bottom: 120px;
}

.pricing-plan .nav {
  gap: 10px;
}

.pricing-plan .nav .nav-item .nav-link {
  color: var(--color-primary);
  border-radius: 50px;
  background: var(--color-white);
}

.pricing-plan .nav .nav-item .active {
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 50px;
  overflow: hidden;
}

.coursesv3 {
  padding-top: 67px;
  padding-bottom: 100px;
  background-image: -webkit-gradient(linear, left top, right top, from(#f1fbff), color-stop(35%, #f1fbff), to(#f1fbff));
  background-image: linear-gradient(90deg, #f1fbff 0%, #f1fbff 35%, #f1fbff 100%);
  background-repeat: no-repeat;
  background-position: bottom;
}

.education-video-watch {
  background-image: -webkit-gradient(linear, left top, right top, from(#f1fbff), color-stop(35%, #f1fbff), to(#f1fbff));
  background-image: linear-gradient(90deg, #f1fbff 0%, #f1fbff 35%, #f1fbff 100%);
  background-size: 100% 500px;
  background-repeat: no-repeat;
  background-position: bottom;
}

.education-video-watch .section-title {
  margin-bottom: 0;
}

/*---------------------------
    Education Video Section
   --------------------------*/
.education-video-section {
  position: relative;
  margin-bottom: 70px;
  border-radius: 20px;
  overflow: hidden;
}

.education-video-section .video-img img {
  width: 100%;
}

.education-video-section .video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  font-size: 20px;
  color: var(--color-primary);
}

.education-video-section .video-icon::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-white);
  border: 0;
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

/*-------------------
  Events Cards
  -------------------*/
.events-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #d5d5d8;
  background: var(--color-white);
  padding: 22px 30px 22px 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.events-card .events-card-content .date {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 5px;
  margin-bottom: 16px;
}

.events-card .events-card-content .date .date-icon {
  color: var(--color-white);
}

.events-card .events-card-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: var(--color-black);
  margin-bottom: 8px;
  width: calc(100% - 30px);
}

.events-card .events-card-content .location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 11px;
}

.events-card .events-card-content .location .location-icon {
  color: var(--color-primary);
}

.events-card .events-card-img {
  border-radius: 10px;
  overflow: hidden;
}

.events-card .events-card-img img {
  width: 100%;
  max-width: 221px;
  max-height: 233px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.events-card .events-card-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*-------------------
  Asked Question
  -------------------*/
.asked-question {
  background: url("../../assets/img/asked-question/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.asked-question .asked-question-right-content {
  padding-left: 73px;
  padding-right: 125px;
  display: none;
}

.asked-question .asked-question-right-content img {
  width: 100%;
}

.asked-question .section-title {
  margin-bottom: 25px;
}

/*-------------------
  News Card
  -------------------*/
.news-card .news-card-img {
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
}

.news-card .news-card-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.news-card .news-card-content {
  padding: 35px 30px;
  border-radius: 0px 0px 15px 15px;
  background: #f6f6f7;
}

.news-card .news-card-content .content-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.news-card .news-card-content .content-top .user-by,
.news-card .news-card-content .content-top .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  font-family: var(--title-font);
  color: var(--color-secondary);
}

.news-card .news-card-content .content-top .user-by .content-icon,
.news-card .news-card-content .content-top .date .content-icon {
  color: var(--color-primary);
  line-height: 0;
}

.news-card .news-card-content .title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-top: 8px;
}

.news-card .news-card-content .para {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--color-paragraph);
  margin-top: 14px;
}

.news-card:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*-------------------
  Client Say
-------------------*/
.client-say {
  background-image: url("../../assets/img/client-say/client-say-bg-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 100px 0;
  position: relative;
}

.client-say .swiper-button {
  position: absolute;
  bottom: 10%;
  right: 32%;
  width: 120px;
}

.client-say .swiper-button .swiper-button-next {
  background: transparent;
  border-radius: 5px;
}

.client-say .swiper-button .swiper-button-next::after {
  content: "";
  padding: 4px 16px;
  font-family: "icomoon";
  border-radius: 5px;
  background: #00afff;
  color: var(--color-white);
  font-size: 20px;
}

.client-say .swiper-button .swiper-button-prev {
  background: transparent;
  border-radius: 5px;
}

.client-say .swiper-button .swiper-button-prev::after {
  content: "";
  padding: 4px 16px;
  font-family: "icomoon";
  border-radius: 5px;
  background: #00afff;
  color: var(--color-white);
  font-size: 20px;
}

.client-say .mySwiper2-client>.swiper-wrapper {
  margin-top: 15%;
}

.client-say .swiper {
  margin-right: 0;
}

.client-say .swiper .client-card-content {
  background: var(--color-white);
  text-align: center;
  position: relative;
  padding: 0 32px 64px 32px;
  border-radius: 10px;
  z-index: 1;
}

.client-say .swiper .client-card-content .content-img img {
  border-radius: 104px;
  border: 6px solid var(--color-white);
  width: 80px;
  height: 80px;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  margin-bottom: -40px;
}

.client-say .swiper .client-card-content .title {
  color: var(--color-primary);
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.client-say .swiper .client-card-content .para {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-style: italic;
  font-family: var(--title-font);
}

.client-say .swiper .client-card-content .star {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 16px;
}

.client-say .swiper .client-card-content .star li {
  font-size: 18px;
}

.client-say .swiper .client-card-content .quote-icon-left {
  position: absolute;
  left: 26px;
  top: 30px;
  z-index: -1;
  color: #e6e6e6;
  font-size: 44px;
  font-weight: 900;
}

.client-say .mySwiper2-client {
  width: 100%;
}

.client-say .mySwiper-client {
  width: 65%;
  position: absolute;
  top: 25%;
}

.client-say .mySwiper-client .swiper-wrapper {
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 32px;
}

.client-say .mySwiper-client .swiper-wrapper .swiper-slide {
  width: 318px !important;
}

.client-say .mySwiper-client .swiper-wrapper .swiper-slide .client-mini-content {
  display: none;
  background: var(--color-white);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  padding: 8px 23px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: pointer;
}

.client-say .mySwiper-client .swiper-wrapper .swiper-slide .client-mini-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.client-say .mySwiper-client .swiper-wrapper .swiper-slide .client-mini-content .para {
  color: var(--color-paragraph);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-family: var(--title-font);
}

.client-say .mySwiper-client .swiper-wrapper .swiper-slide .client-mini-content:hover {
  background: var(--color-primary);
}

.client-say .mySwiper-client .swiper-wrapper .swiper-slide .client-mini-content:hover .title,
.client-say .mySwiper-client .swiper-wrapper .swiper-slide .client-mini-content:hover .para {
  color: var(--color-white5);
}

.client-say .mySwiper-client .swiper-wrapper .swiper-slide img {
  border: 2px solid var(--color-white);
  border-radius: 82px;
  width: 80px;
  height: 80px;
}

/*-------------------
  Breadcum Content
-------------------*/
.breadcum-content a {
  color: var(--color-white);
}

.breadcum-content .title {
  color: var(--color-white);
  font-size: 60px;
  line-height: 70px;
  font-weight: 600;
  margin-bottom: 30px;
}

.breadcum-content .para {
  color: var(--color-white);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  text-transform: uppercase;
}

/*-------------------
  Breadcum
-------------------*/
.breadcum {
  background-image: url("../../assets/img/breadcum/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 150px 0 150px 0;
  text-align: center;
}

/*------------------------------
  Courses Details left content
--------------------------------*/
.courses-details-left-content .top-content {
  padding: 30px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  background: var(--color-white5);
  margin-bottom: 40px;
}

.courses-details-left-content .top-content .courses-img {
  margin-bottom: 50px;
}

.courses-details-left-content .top-content .courses-img img {
  width: 100%;
  border-radius: 20px;
}

.courses-details-left-content .top-content .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 500;
  color: var(--color-black);
}

.courses-details-left-content .top-content .instructor-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 12px 0;
  margin-top: 40px;
  gap: 10px;
}

.courses-details-left-content .top-content .instructor-info>li {
  padding-right: 53px;
}

.courses-details-left-content .top-content .instructor-info>li .title {
  color: var(--color-black);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 12px;
}

.courses-details-left-content .top-content .instructor-info>li .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-details-left-content .top-content .instructor-info>li:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 14px;
}

.courses-details-left-content .top-content .instructor-info>li:last-child {
  padding-right: 0px;
  border-right: none;
}

.courses-details-left-content .top-content .instructor-info>li:last-child .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 7px;
}

.courses-details-left-content .top-content .instructor-info>li:last-child .rating .star li {
  font-size: 12px;
}

.courses-details-left-content .top-content .instructor-info>li:last-child .rating .point {
  color: var(--color-primary);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.courses-details-left-content .top-content .instructor-info>li:last-child .rating .point-para {
  color: var(--color-black);
  font-family: var(--body-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.courses-details-left-content .top-content .instructor-info>li:last-child .rating .point-para span {
  color: var(--color-paragraph);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 3px;
}

.courses-details-left-content .top-content .instructor-info .instructor-img img {
  width: 49px;
  height: 49px;
}

.courses-details-left-content .middle-content {
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  background: var(--color-white5);
  margin-bottom: 40px;
}

.courses-details-left-content .middle-content .nav {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 35px;
  gap: 10px;
}

.courses-details-left-content .middle-content .nav.nav-tabs {
  border-bottom: none;
}

.courses-details-left-content .middle-content .nav .nav-link {
  border-radius: 5px;
  border: 1px solid #ededed;
  background: var(--color-white6);
  -webkit-box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  padding: 15px 40px;
  color: var(--color-primary);
  font-family: var(--title-font);
}

.courses-details-left-content .middle-content .nav .nav-link.active {
  background: var(--color-primary);
  color: var(--color-white);
}

.courses-details-left-content .middle-content .tab-content .overview-content .title {
  color: var(--color-black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 29px;
}

.courses-details-left-content .middle-content .tab-content .overview-content .para,
.courses-details-left-content .middle-content .tab-content .overview-content .para2 {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.courses-details-left-content .middle-content .tab-content .overview-content .para2 {
  margin-top: 30px;
  margin-bottom: 40px;
}

.courses-details-left-content .middle-content .tab-content .overview-content .mini-title {
  color: var(--color-black);
  font-size: 26px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 20px;
}

/*-------------------
  Service Cards
-------------------*/
.service-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 40px;
  margin: 35px 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.service-cards .card-content {
  border-radius: 15px;
  border: 1px solid rgba(237, 237, 237, 0.89);
  background: var(--color-white);
  -webkit-box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  padding: 35px 30px;
}

.service-cards .card-content .card-title {
  color: #1c1c1c;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.service-cards .card-content .title {
  color: var(--color-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 15px;
}

.service-cards .card-content .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

/*-----------------------------
  Courses details right content
-------------------------------*/
.courses-details-right-content .side-bar-top-content {
  border-radius: 20px;
  border: 1px solid var(--color-white7);
  background: var(--color-white5);
  padding: 40px;
}

.courses-details-right-content .side-bar-top-content .side-bar-img {
  margin-bottom: 24px;
}

.courses-details-right-content .side-bar-top-content .side-bar-img img {
  width: 100%;
  border-radius: 15px;
}

.courses-details-right-content .side-bar-top-content .courses-pricing {
  color: var(--color-primary);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 9px;
}

.courses-details-right-content .side-bar-top-content .price {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 21px;
}

.courses-details-right-content .side-bar-top-content .price sub {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  bottom: 0;
}

.courses-details-right-content .side-bar-top-content .btn-p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.courses-details-right-content .side-bar-top-content .courses-features {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-top: 40px;
}

.courses-details-right-content .side-bar-top-content .courses-details-list {
  margin-top: 26px;
}

.courses-details-right-content .side-bar-top-content .courses-details-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-white7);
  margin-bottom: 20px;
}

.courses-details-right-content .side-bar-top-content .courses-details-list li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.courses-details-right-content .side-bar-top-content .courses-details-list li .para {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--title-font);
}

.courses-details-right-content .side-bar-top-content .courses-details-list li .list-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 11px;
}

.courses-details-right-content .side-bar-top-content .courses-details-list li .list-left-content .list-icon {
  color: var(--color-primary);
  line-height: 0;
}

.courses-details-right-content .side-bar-bottom-content {
  border-radius: 20px;
  border: 1px solid var(--color-white7);
  background: var(--color-white5);
  padding: 40px;
  margin-top: 40px;
}

.courses-details-right-content .side-bar-bottom-content .title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.courses-details-right-content .side-bar-bottom-content .working-hours {
  margin-top: 28px;
}

.courses-details-right-content .side-bar-bottom-content .working-hours li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid rgba(237, 237, 237, 0.89);
  background: var(--color-white);
  -webkit-box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  padding: 12px 17px;
  margin-bottom: 15px;
  color: var(--color-paragraph);
}

.courses-details-right-content .side-bar-bottom-content .working-hours li:last-child {
  margin-bottom: 0;
}

/*-------------------
  Courses List Btn
-------------------*/
.courses-list-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 11px;
  width: 100%;
}

.courses-list-btn li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid var(--color-primary);
  padding: 15px 17px;
  -webkit-box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.courses-list-btn li:hover a {
  background: var(--color-primary);
  color: var(--color-white);
}

/*---------------------
  Instructor Content
----------------------*/
.instructor-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 40px;
  border-radius: 15px;
  border: 1px solid var(--color-white7);
  background: var(--color-white5);
  padding: 30px 30px 40px 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.instructor-content .instructor-left-content .left-content-img img {
  width: 100%;
  border-radius: 15px;
}

.instructor-content .instructor-left-content .instructor-social-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.instructor-content .instructor-left-content .instructor-social-link li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid #ededed;
  background: var(--color-white6);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 14px;
}

.instructor-content .instructor-left-content .instructor-social-link li:hover a {
  background: var(--color-primary);
  color: var(--color-white);
}

.instructor-content .instructor-right-content .title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.instructor-content .instructor-right-content .tag {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 9px;
  font-family: var(--title-font);
}

.instructor-content .instructor-right-content .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

/*-------------------
  Team Member Data
-------------------*/
.team-member-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #e5e5e7;
  padding: 20px;
  gap: 60px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.team-member-data .team-img {
  border-radius: 20px;
  overflow: hidden;
}

.team-member-data .team-img img {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.team-member-data .team-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.team-member-data .team-content .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.team-member-data .team-content .team-info {
  margin-bottom: 25px;
}

.team-member-data .team-content .team-info .title {
  color: var(--color-black);
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
}

.team-member-data .team-content .team-social-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--color-primary);
}

.team-member-data .team-content .team-social-link li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: var(--color-white);
  -webkit-box-shadow: 0px 7px 23px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 7px 23px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  color: var(--color-paragraph);
  font-size: 12px;
  font-weight: 400;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.team-member-data .team-content .team-social-link li:hover a {
  color: var(--color-primary);
}

.team-member-data .team-content .team-member-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 28px 50px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 24px;
}

.team-member-data .team-content .team-member-details li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.team-member-data .team-content .team-member-details li .details-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  -webkit-box-shadow: 0px 10px 35px rgba(35, 31, 80, 0.13);
  box-shadow: 0px 10px 35px rgba(35, 31, 80, 0.13);
  border-radius: 100%;
  color: var(--color-primary);
}

.team-member-data .team-content .team-member-details li .details-right-content .para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.team-member-data .team-content .team-member-details li .details-right-content .title {
  font-family: var(--body-font);
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: var(--color-black);
}

/*-------------------
  Team Details
-------------------*/
.team-details {
  margin: 120px 0;
}

.team-details .team-bottom-content {
  margin-top: 80px;
}

.team-details .team-bottom-content .title {
  color: var(--color-black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 15px;
}

.team-details .team-bottom-content .para,
.team-details .team-bottom-content .para2 {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.team-details .team-bottom-content .para {
  margin-bottom: 16px;
}

.team-details .team-bottom-content .para2 {
  margin-bottom: 16px;
}

.team-details .team-bottom-content .para2:last-child {
  margin-bottom: 0;
}

.team-details .team-bottom-content .skill-content .skill-left-content {
  padding-right: 40px;
}

.team-details .progress-bars {
  margin-bottom: 0;
}

.team-details .progress-bars .title {
  margin-bottom: 0;
}

.team-details .progress-bars .progress-title {
  margin-bottom: 0;
}

/*-------------
  Get Tickets
---------------*/
.get-ticket {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 28px;
}

.get-ticket .get-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-black);
}

.get-ticket .get-title a {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.get-ticket .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}

.get-ticket .social li {
  color: var(--color-primary);
}

.get-ticket .social li:hover {
  color: var(--color-secondary);
}

/*------------------------------
  Events Details left content
---------------------------------*/
.events-details-left-content .left-content-img {
  margin-bottom: 50px;
}

.events-details-left-content .left-content-img img {
  width: 100%;
  border-radius: 20px;
}

.events-details-left-content .title {
  color: var(--color-black);
  font-size: 36px;
  line-height: 46px;
  font-weight: 500;
  margin-bottom: 32px;
  width: calc(100% - 35px);
}

.events-details-left-content .para,
.events-details-left-content .para2,
.events-details-left-content .para3 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-paragraph);
  font-family: var(--body-font);
}

.events-details-left-content .para {
  margin-bottom: 26px;
}

.events-details-left-content .count-down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 50px 0;
}

.events-details-left-content .count-down li {
  border-radius: 10px;
  border: 2px solid var(--color-primary);
  background: var(--color-white);
  text-align: center;
  width: 113px;
  height: 97px;
}

.events-details-left-content .count-down li .count-title {
  color: var(--color-primary);
  font-size: 48px;
  line-height: 58px;
  font-weight: 500;
}

.events-details-left-content .count-down li .count-para {
  color: var(--color-paragraph);
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

.events-details-left-content .what-you-will-leran {
  margin-top: 27px;
}

.events-details-left-content .what-you-will-leran .learn-title {
  color: var(--color-black);
  font-size: 26px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 16px;
}

.events-details-left-content .what-you-will-leran .learn-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 8px;
}

.events-details-left-content .what-you-will-leran .learn-list li:last-child {
  margin-bottom: 0;
}

.events-details-left-content .what-you-will-leran .learn-list li .list-icon {
  color: var(--color-primary);
  line-height: 0;
}

.events-details-left-content .what-you-will-leran .learn-list li .learn-para {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-black);
  font-family: var(--body-font);
}

.events-details-left-content .share-post {
  margin-top: 38px;
}

.events-details-left-content .share-post .share-title {
  color: var(--color-black);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 13px;
}

/*-------------------------------
  Events Details Right Content
---------------------------------*/
.share-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
}

.share-list li a {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background: var(--color-white6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.share-list li a:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

/*-------------------------------
  Events Details Right Content
---------------------------------*/
.events-details-right-content {
  border-radius: 20px;
  background: var(--color-white5);
  padding: 40px 25px;
}

.events-details-right-content .events-slider {
  margin-bottom: 40px;
}

.events-details-right-content .events-slider .swiper-slide img {
  width: 100%;
  border-radius: 15px;
}

.events-details-right-content .events-slider .swiper-button-next::after {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  content: "";
  font-family: "icomoon";
  font-size: 14px;
  min-width: 36px;
  height: 36px;
  background: var(--color-primary);
  border: 1px solid var(--color-white);
  color: var(--color-white);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.events-details-right-content .events-slider .swiper-button-prev::after {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  content: "";
  font-family: "icomoon";
  font-size: 14px;
  min-width: 36px;
  height: 36px;
  background: var(--color-primary);
  border: 1px solid var(--color-white);
  color: var(--color-white);
  border-radius: 50%;
}

.events-details-right-content .title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 30px;
}

.events-details-right-content .events-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-white7);
  margin-bottom: 20px;
}

.events-details-right-content .events-info li .info-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 11px;
  color: var(--color-black);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: var(--title-font);
}

.events-details-right-content .events-info li .info-title .info-icon {
  color: var(--color-primary);
  font-size: 16px;
  line-height: 0;
}

.events-details-right-content .events-info li .info-para {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--title-font);
}

.events-details-right-content .total-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.events-details-right-content .total-price .price-para {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--title-font);
}

.events-details-right-content .total-price .price-title {
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  font-family: var(--title-font);
}

.events-details-right-content .total-price .price-title span {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--title-font);
}

.events-details-right-content .bay-ticket-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
}

.events-details-right-content .bay-ticket-btn .btn-p {
  color: var(--color-white);
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  text-transform: uppercase;
}

/*-------------------
  Courses Grid Nav
-------------------*/
.courses-grid-nav {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  background: var(--color-white);
  margin-bottom: 50px;
}

.courses-grid-nav .nav-tabs {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px 16px 8px 30px;
  width: 100%;
}

.courses-grid-nav .nav-tabs .nav-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.courses-grid-nav .nav-tabs .nav-left-content .content-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.courses-grid-nav .nav-tabs .nav-left-content .content-icon .nav-link {
  border: none;
  color: var(--color-paragraph);
  padding: 0;
}

.courses-grid-nav .nav-tabs .nav-left-content .content-icon .nav-link.active {
  color: var(--color-primary);
}

.courses-grid-nav .nav-tabs .nav-left-content .showing-list {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
}

.courses-grid-nav .nav-tabs .nav-right-content {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--color-border);
}

.courses-grid-nav .nav-tabs .nav-right-content .category {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
  padding: 0 10px;
  gap: 92px;
  position: relative;
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
}

.courses-grid-nav .nav-tabs .nav-right-content .category select {
  color: var(--color-paragraph);
}

.courses-grid-nav .nav-tabs .nav-right-content .category::after {
  content: "";
  position: absolute;
  right: 0;
  background: var(--color-border);
  width: 1px;
  height: 39px;
}

.courses-grid-nav .nav-tabs .nav-right-content .default-shorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
}

.courses-grid-nav .nav-tabs .nav-right-content .default-shorting input {
  padding-left: 10px;
}

.courses-grid-nav .nav-tabs .nav-right-content .default-shorting .search-icon {
  border-radius: 0px 5px 5px 0px;
  background: var(--color-primary);
  width: 43px;
  height: 39px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-white);
}

/*-----------------------
  Courses Search Option
-------------------------*/
.courses-search-option {
  border-radius: 20px;
  border: 1px solid var(--color-white5);
  background: var(--color-white5);
  padding: 30px 40px;
}

.courses-search-option input {
  background: var(--color-white);
}

.courses-search-option form {
  background: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.courses-search-option form input {
  padding-left: 20px;
  width: 100%;
}

.courses-search-option form input::-webkit-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-search-option form input::-moz-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-search-option form input:-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-search-option form input::-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-search-option form input::placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-search-option button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background: var(--color-primary);
  min-width: 55px;
  max-width: 55px;
  height: 55px;
  color: var(--color-white);
}

/*-------------------------
  Courses grid categories
---------------------------*/
.courses-grid-categories {
  margin-top: 30px;
  border-radius: 20px;
  background: var(--color-white5);
  padding: 25px 40px;
}

.courses-grid-categories .category-title {
  color: var(--color-black);
  font-size: 24px;
  font-weight: 500;
}

.courses-grid-categories .category-list {
  margin-top: 8px;
}

.courses-grid-categories .category-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 13px;
}

.courses-grid-categories .category-list li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.courses-grid-categories .category-list li input {
  height: inherit;
}

.courses-grid-categories .category-list li .list-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--title-font);
}

.courses-grid-categories .category-list li .list-point {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--title-font);
}

/*---------------------------
  Courses grid price lavel
-----------------------------*/
.courses-grid-price-lavel {
  margin-top: 30px;
  border-radius: 20px;
  background: var(--color-white5);
  padding: 40px;
}

.courses-grid-price-lavel .price-lavel-title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.courses-grid-price-lavel .lavel-list {
  margin-top: 13px;
}

.courses-grid-price-lavel .lavel-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 13px;
  padding-bottom: 12px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--color-border);
  font-family: var(--title-font);
}

.courses-grid-price-lavel .lavel-list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.courses-grid-price-lavel .lavel-list li input {
  height: inherit;
}

.courses-grid-price-lavel .price-filter {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.courses-grid-price-lavel .price {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.courses-grid-price-lavel .star-rating {
  margin-top: 14px;
}

.courses-grid-price-lavel .star-rating .rating-title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.courses-grid-price-lavel .star-rating .stars {
  margin-top: 14px;
}

.courses-grid-price-lavel .star-rating .stars .star-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 13px;
}

.courses-grid-price-lavel .star-rating .stars .star-list input {
  height: inherit;
}

/*-------------------
  Blog Card Content
-------------------*/
.blog-card-content {
  border-radius: 20px;
  background: var(--color-white5);
  overflow: hidden;
  margin-bottom: 24px;
}

.blog-card-content:last-child {
  margin-bottom: 0;
}

.blog-card-content .blog-card-img {
  overflow: hidden;
}

.blog-card-content .blog-card-img img {
  width: 100%;
  max-height: 500px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-card-content .blog-card-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog-card-content .card-info {
  padding: 20px;
}

.blog-card-content .card-info .card-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  gap: 20px;
}

.blog-card-content .card-info .card-top-content .card-time,
.blog-card-content .card-info .card-top-content .card-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  color: var(--color-secondary);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--title-font);
}

.blog-card-content .card-info .card-title {
  color: var(--color-black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 17px;
}

.blog-card-content .card-info .card-para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 37px;
}

/*-------------------
  Recent Events
-------------------*/
.recent-events {
  padding: 30px 40px;
  border-radius: 20px;
  background: var(--color-white5);
  margin-top: 30px;
}

.recent-events .recent-title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  margin-bottom: 19px;
}

.recent-events .recent-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 17px;
}

.recent-events .recent-list li:last-child {
  margin-bottom: 0;
}

.recent-events .recent-list li .list-img img {
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.recent-events .recent-list li .list-content .content-title {
  color: var(--color-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 3px;
}

.recent-events .recent-list li .list-content .content-para {
  color: var(--color-paragraph);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--title-font);
}

/*-------------------
  Categories Content
-------------------*/
.categories-content {
  border-radius: 20px;
  background: var(--color-white5);
  padding: 30px 40px;
  margin-top: 30px;
}

.categories-content .categories-title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.categories-content .categories-list {
  margin-top: 15px;
}

.categories-content .categories-list li {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 14px;
  margin-bottom: 16px;
}

.categories-content .categories-list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.categories-content .categories-list li a {
  color: var(--color-paragraph);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
}

.categories-content .categories-list li:hover a {
  color: var(--color-primary);
}

/*-------------------
  Project Gallery
-------------------*/
.project-gallery {
  border-radius: 20px;
  background: var(--color-white5);
  padding: 30px 40px;
  margin-top: 30px;
}

.project-gallery .gallery-title {
  color: var(--color-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.project-gallery .gallery-list {
  margin-top: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

.project-gallery .gallery-list li img {
  width: 95px;
  height: 95px;
  border-radius: 10px;
}

/*-------------------
  Popular Tags
-------------------*/
.popular-tags {
  padding: 30px 40px;
  margin-top: 30px;
  border-radius: 20px;
  background: var(--color-white5);
}

.popular-tags .popular-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.popular-tags .popular-list li a {
  background: var(--color-white);
  border-radius: 5px;
  padding: 10px 10px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-paragraph);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.popular-tags .popular-list li:hover a {
  background: var(--color-primary);
  color: var(--color-white);
}

/*-------------------
  Blog Details Img
-------------------*/
.blog-details-img {
  margin-bottom: 45px;
}

.blog-details-img img {
  border-radius: 20px;
  width: 100%;
  max-height: 500px;
  -o-object-fit: cover;
  object-fit: cover;
}

/*-------------------------
  Blog Details Top Content
---------------------------*/
.blog-details-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 9px;
}

.blog-details-top-content .card-time,
.blog-details-top-content .card-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  color: var(--color-black);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--title-font);
}

/*-------------------
  Blog Content Info
-------------------*/
.blog-content-info .blog-details-title {
  color: var(--color-black);
  font-size: 36px;
  line-height: 46px;
  font-weight: 500;
  margin-bottom: 22px;
}

.blog-content-info .para1,
.blog-content-info .para2 {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.blog-content-info .para1 {
  margin-bottom: 20px;
}

/*-------------------
  Top Author
-------------------*/
.top-author {
  border-radius: 15px;
  background: var(--color-white5);
  padding: 20px;
  margin: 30px 0;
}

.top-author .top-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: var(--color-primary);
  border-radius: 50%;
  margin-bottom: 20px;
  color: var(--color-white);
  font-size: 20px;
}

.top-author .author-title {
  color: var(--color-black);
  font-family: var(--body-font);
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}

.top-author .author-name {
  color: var(--color-primary);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.top-author .author-tag {
  color: var(--color-paragraph);
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--title-font);
}

/*------------------------
  Blog Details Middle Img
--------------------------*/
.blog-details-middle-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 30px 0;
}

.blog-details-middle-img .middle-img {
  width: 50%;
}

.blog-details-middle-img .middle-img img {
  width: 100%;
  max-height: 350px;
  border-radius: 15px;
  -o-object-fit: cover;
  object-fit: cover;
}

/*-------------------
  Post Tags
-------------------*/
.post-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--color-border);
  margin: 40px 0;
}

.post-tags .tag-title {
  color: var(--color-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.post-tags .tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

.post-tags .tag-list li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.post-tags .tag-list li a {
  border-radius: 5px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid rgba(237, 237, 237, 0.89);
  background: var(--color-white);
  -webkit-box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  color: var(--color-paragraph);
}

.post-tags .tag-list li:hover a {
  background: var(--color-primary);
  color: var(--color-white);
  border: 1px solid var(--color-primary);
}

/*-------------------
  Comments
-------------------*/
.comments {
  margin-top: 68px;
}

.comments .comments-list {
  margin-top: 25px;
}

.comments .comments-list .comments-list .list-content {
  padding-left: 50px;
}

.comments .comments-list li {
  margin-bottom: 30px;
}

.comments .comments-list li .list-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-border);
}

.comments .comments-list li .list-content .list-img img {
  width: 110px;
  height: 110px;
  border-radius: 15px;
}

.comments .comments-list li .list-content .list-info .list-top-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comments .comments-list li .list-content .list-info .list-top-info .info-data {
  color: var(--color-paragraph);
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

.comments .comments-list li .list-content .list-info .list-top-info .user-name {
  color: var(--color-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.comments .comments-list li .list-content .list-info .comments-para {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 5px;
}

/*-------------------
  Comments Area
-------------------*/
.comments-area {
  border-radius: 15px;
  background: var(--color-white5);
  padding: 40px 20px;
  margin-top: 80px;
}

.comments-area .comments-heading {
  color: var(--color-black);
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 28px;
}

.comments-area .form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
}

.comments-area .form-box .box-info {
  background: var(--color-white);
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comments-area .form-box .box-info input {
  padding: 20px 25px;
  width: 100%;
}

.comments-area .form-box .box-info input::-webkit-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box .box-info input::-moz-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box .box-info input:-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box .box-info input::-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box .box-info input::placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box-2 textarea {
  width: 100%;
  height: 150px;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.comments-area .form-box-2 textarea::-webkit-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box-2 textarea::-moz-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box-2 textarea:-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box-2 textarea::-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .form-box-2 textarea::placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.comments-area .submit-btn {
  background: var(--color-primary);
  color: var(--color-white);
  padding: 12px 27px;
  border-radius: 5px;
  margin-top: 36px;
}

/*-------------------
  Error Content
-------------------*/
.error-content .error-img img {
  width: 100%;
}

.error-content .error-title {
  color: var(--color-black);
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  text-align: center;
  margin-top: 80px;
}

.error-content .error-title span {
  color: var(--color-primary);
}

.error-content .error-para {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  color: var(--color-paragraph);
  font-family: var(--body-font);
}

.contact-bg-color {
  background: #f6f7fa;
}

/*-------------------
  Contact Us
-------------------*/
.contact-us-content {
  background-image: url("../../assets/img/contact-us/contact-us-bg-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.contact-us-content .contact-us-img {
  display: none;
  width: 100%;
}

.contact-us-content .contact-us-img img {
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
  object-fit: cover;
}

.contact-us-content .contact-us-right-content {
  width: 100%;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.contact-us-content .contact-us-right-content .section-title {
  margin-bottom: 30px;
}

.contact-us-content .contact-us-right-content .bg-map-contact-us {
  position: absolute;
  top: 22%;
  left: 0;
  z-index: -1;
}

.contact-us-content .contact-us-right-content .bg-map-contact-us img {
  width: 100%;
}

/*------------------------
  Contact Us Contact Info
---------------------------*/
.contact-us-contact-info {
  background: #f6f7fa;
  padding-bottom: 110px;
}

.contact-us-contact-info .info-list .info-list-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  background: var(--color-white);
  padding: 30px 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 16px 51px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-bottom: 2px solid var(--color-white);
}

.contact-us-contact-info .info-list .info-list-content .info-icon {
  color: var(--color-primary);
  font-size: 48px;
  line-height: 58px;
  font-weight: 900;
}

.contact-us-contact-info .info-list .info-list-content .mini-title {
  color: var(--color-primary);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.contact-us-contact-info .info-list .info-list-content .big-title {
  color: #1c2539;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
}

.contact-us-contact-info .info-list .info-list-content:hover {
  border-bottom: 2px solid var(--color-primary);
}

.mySwiper-logo .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mySwiper-logo .swiper-slide img {
  opacity: 0.3;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mySwiper-logo .swiper-slide img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.my-120 {
  margin: 120px 0;
}

.py-120 {
  padding: 120px 0;
}

.pt-100 {
  padding-top: 100px;
}

.py-100 {
  padding: 100px 0;
}

.pb-120 {
  padding-bottom: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

/*-------------------
  Top to bottom
-------------------*/
.bottom-to-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.to-top {
  background: var(--color-primary);
  position: fixed;
  bottom: 16px;
  right: 32px;
  opacity: 0;
  pointer-events: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.to-top .to-top-icon {
  color: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 38px;
}

.to-top.active {
  bottom: 32px;
  pointer-events: auto;
  opacity: 1;
  z-index: 999;
  display: block;
}

/*-------------------
  Login Form
-------------------*/
.login-section {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
}

.login-section .signin {
  width: 400px;
  background: var(--color-white);
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px;
  border-radius: 4px;
  -webkit-box-shadow: 3px 4px 10px 4px rgba(23, 155, 215, 0.12);
  box-shadow: 3px 4px 10px 4px rgba(23, 155, 215, 0.12);
}

.login-section .signin .content {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 40px;
}

.login-section .signin .content .login-title {
  font-size: 2em;
  color: var(--color-primary);
  text-transform: uppercase;
}

.login-section .signin .content .form {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 25px;
}

.login-section .signin .content .form .inputBox {
  width: 100%;
}

.login-section .signin .content .form .inputBox input {
  width: 100%;
  -webkit-box-shadow: 3px 4px 10px 4px rgba(23, 155, 215, 0.12);
  box-shadow: 3px 4px 10px 4px rgba(23, 155, 215, 0.12);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1em;
}

.login-section .signin .content .form .inputBox input::-webkit-input-placeholder {
  color: #7B7E86;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.login-section .signin .content .form .inputBox input::-moz-placeholder {
  color: #7B7E86;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.login-section .signin .content .form .inputBox input:-ms-input-placeholder {
  color: #7B7E86;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.login-section .signin .content .form .inputBox input::-ms-input-placeholder {
  color: #7B7E86;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.login-section .signin .content .form .inputBox input::placeholder {
  color: #7B7E86;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.login-section .signin .content .links {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-section .signin .content .links a {
  color: var(--color-primary);
  text-decoration: none;
}

.pagination-button {
  margin-top: 80px;
}

.pagination-button ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.pagination-button ul li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background: #f6f6f7;
  color: var(--color-black);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}

.pagination-button ul li:hover a {
  background: var(--color-primary);
  color: var(--color-white);
}

.our-courses-plan.v3 {
  background: #f2f7f9;
}

/*-------------------
  Progress Bars
-------------------*/
.progress-bars {
  margin-bottom: 47px;
  width: 90%;
}

.progress-bars li {
  margin-bottom: 11px;
  width: 100%;
}

.progress-bars li:last-child {
  margin-bottom: 0;
}

.progress-bars li .progress-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 6px;
}

.progress-bars li .progress-title .title {
  color: #1c1c1c;
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 400;
}

.my-progress-bar {
  padding: 5px;
  border-radius: 100px;
  border: 1px solid #d5e0f5;
  background: #fff;
  -webkit-box-shadow: 0px 10px 31px 0px rgba(169, 177, 193, 0.17);
  box-shadow: 0px 10px 31px 0px rgba(169, 177, 193, 0.17);
}

.my-progress-bar .progress-vale {
  height: 6px;
  border-radius: 25px;
  background: var(--color-primary);
}

.reviews-content-inner {
  margin-top: 35px;
  border: 1px solid var(--color-border);
  border-radius: 10px;
}

.reviews-content-inner>li {
  padding: 27px 0;
  border-bottom: 1px solid var(--color-border);
}

.reviews-content-inner>li:last-child {
  border-bottom: none;
}

.reviews-content-inner li:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reviews-content-inner .rating-point-content .rating-points {
  font-weight: 600;
  font-size: 80px;
  line-height: 81px;
  color: var(--color-primary);
}

.reviews-content-inner .rating-point-content .star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.reviews-content-inner .rating-point-content .star li {
  font-size: 20px;
}

.yearly-star-rating {
  padding: 0 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yearly-star-rating .star {
  margin-bottom: 10px;
}

.yearly-star-rating .star li {
  font-size: 16px;
}

.yearly-star-rating .year-title {
  margin-bottom: 10px;
  width: 100%;
}

.yearly-star-rating .star-rating-content {
  width: 100%;
}

.news-letter {
  padding: 20px 20px;
  background: var(--color-white);
  border-radius: 10px;
  -webkit-transform: translateY(-60px);
  transform: translateY(-60px);
  border: 1px solid var(--color-border);
}

.news-letter .title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

.news-letter form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 6px 8px 29px;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  -webkit-box-shadow: 0px 6px 26px 0px #e4e8f1;
  box-shadow: 0px 6px 26px 0px #e4e8f1;
  width: 100%;
}

.news-letter form input {
  padding: 0;
  width: 100%;
}

.news-letter form input::-webkit-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.news-letter form input::-moz-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.news-letter form input:-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.news-letter form input::-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.news-letter form input::placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

/*-------------------
  Footer Content
  -------------------*/
.footer-content.v1 {
  background-image: url("../../assets/img/footer/v1/background-big-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 120px;
  position: relative;
  z-index: 0;
  margin-top: 38px;
}

.footer-content.v1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(196deg, rgba(5, 25, 60, 0.05) 12.4%, #051838 80.63%);
  z-index: -1;
}

.footer-content.v1 .footer-widget .widget-para {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-white);
  margin-top: 18px;
  max-width: 100%;
  width: 80%;
}

.footer-content.v1 .footer-widget .widget-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: var(--color-white);
  padding-bottom: 15px;
  position: relative;
}

.footer-content.v1 .footer-widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-primary);
  width: 41px;
  height: 3px;
}

.footer-content.v1 .footer-widget .quick-links,
.footer-content.v1 .footer-widget .our-courses {
  margin-top: 29px;
}

.footer-content.v1 .footer-widget .quick-links li,
.footer-content.v1 .footer-widget .our-courses li {
  margin-bottom: 18px;
}

.footer-content.v1 .footer-widget .quick-links li:last-child,
.footer-content.v1 .footer-widget .our-courses li:last-child {
  margin-bottom: 0;
}

.footer-content.v1 .footer-widget .quick-links li a,
.footer-content.v1 .footer-widget .our-courses li a {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--color-white);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* .footer-content.v1 .footer-widget .quick-links li a::before,
.footer-content.v1 .footer-widget .our-courses li a::before {
  content: "";
  font-family: "icomoon";
  font-size: calc(100% - 5px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 5px;
} */

.footer-content.v1 .footer-widget .quick-links li a:hover,
.footer-content.v1 .footer-widget .our-courses li a:hover {
  color: var(--color-primary);
}

.footer-content.v1 .footer-widget .get-in-touch {
  margin-top: 37px;
}

.footer-content.v1 .footer-widget .get-in-touch .contact-info-items li {
  margin-bottom: 16px;
}

.footer-content.v1 .footer-widget .get-in-touch .contact-info-items li:last-child {
  margin-bottom: 0;
}

.footer-content.v1 .footer-widget .get-in-touch .contact-info-items .contact-info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.footer-content.v1 .footer-widget .get-in-touch .contact-info-items .contact-info-item .contact-info-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  background: #2a4c60;
  color: var(--color-white);
  font-size: 20px;
  line-height: 30px;
}

.footer-content.v1 .footer-widget .get-in-touch .contact-info-items .contact-info-item .contact-info-content .para {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--color-white);
}

.footer-content.v1 .footer-widget .get-in-touch .contact-info-items .contact-info-item .contact-info-content .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: var(--color-white);
}

.footer-content.v1 .bg-left-color-img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.footer-content.v1 .bg-left-color-img img {
  height: 500px;
}

.footer-content.v1 .bg-right-color-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.footer-content.v1 .bg-right-color-img img {
  height: 500px;
}

.footer-content.v2 {
  background-image: url("../../assets/img/footer/v2/footer-two-bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0;
}

.footer-content.v2 .footer-widget.ml-25 {
  margin-left: 25px;
}

.footer-content.v2 .footer-widget .footer-logo {
  margin-bottom: 17px;
}

.footer-content.v2 .footer-widget .widget-para {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-white);
  margin-bottom: 29px;
  width: calc(100% - 90px);
}

.footer-content.v2 .footer-widget .gallery-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

.footer-content.v2 .footer-widget .gallery-content li {
  position: relative;
}

.footer-content.v2 .footer-widget .gallery-content li img {
  width: 100%;
  border-radius: 10px;
}

.footer-content.v2 .footer-widget .gallery-content li::before {
  content: "";
  height: calc(100% - 14px);
  width: calc(100% - 14px);
  background: var(--color-primary);
  opacity: 0.8;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 10px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer-content.v2 .footer-widget .gallery-content li .gallery-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
  color: var(--color-white);
  visibility: hidden;
  opacity: 0;
}

.footer-content.v2 .footer-widget .gallery-content li:hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.footer-content.v2 .footer-widget .gallery-content li:hover .gallery-btn {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer-content.v2 .footer-widget .widget-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: var(--color-white);
  padding-bottom: 15px;
  position: relative;
}

.footer-content.v2 .footer-widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-primary);
  width: 41px;
  height: 3px;
}

.footer-content.v2 .footer-widget .quick-links {
  margin-top: 30px;
}

.footer-content.v2 .footer-widget .quick-links li {
  margin-bottom: 18px;
}

.footer-content.v2 .footer-widget .quick-links li a {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--color-white);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer-content.v2 .footer-widget .quick-links li a::before {
  content: "";
  font-family: "icomoon";
  font-size: calc(100% - 5px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 5px;
}

.footer-content.v2 .footer-widget .quick-links li a:hover {
  color: var(--color-primary);
}

.footer-content.v2 .footer-widget .get-newsletter {
  margin-top: 24px;
}

.footer-content.v2 .footer-widget .get-newsletter .para {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-white);
}

.footer-content.v2 .footer-widget .get-newsletter .email-address {
  margin-top: 21px;
  background: var(--color-white);
  border-radius: 5px;
  margin-bottom: 20px;
}

.footer-content.v2 .footer-widget .get-newsletter .email-address form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
  padding: 16px 20px;
}

.footer-content.v2 .footer-widget .get-newsletter .email-address form input {
  width: 100%;
}

.footer-content.v2 .footer-widget .get-newsletter .email-address form input::-webkit-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v2 .footer-widget .get-newsletter .email-address form input::-moz-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v2 .footer-widget .get-newsletter .email-address form input:-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v2 .footer-widget .get-newsletter .email-address form input::-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v2 .footer-widget .get-newsletter .email-address form input::placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v3 {
  padding: 100px 0 95px 0;
}

.footer-content.v3::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(196deg, rgba(5, 25, 60, 0.05) 12.4%, #051838 80.63%);
  z-index: -1;
}

.footer-content.v3 iframe {
  margin-bottom: 110px;
}

.footer-content.v3 .footer-widget .footer-logo {
  margin-bottom: 24px;
}

.footer-content.v3 .footer-widget .widget-para {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-white);
  margin-bottom: 29px;
  max-width: 100%;
  width: 95%;
}

.footer-content.v3 .footer-widget .gallery-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

.footer-content.v3 .footer-widget .gallery-content li {
  position: relative;
}

.footer-content.v3 .footer-widget .gallery-content li img {
  width: 100%;
  border-radius: 10px;
}

.footer-content.v3 .footer-widget .gallery-content li::before {
  content: "";
  height: calc(100% - 14px);
  width: calc(100% - 14px);
  background: var(--color-primary);
  opacity: 0.8;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 10px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer-content.v3 .footer-widget .gallery-content li .gallery-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
  color: var(--color-white);
  visibility: hidden;
  opacity: 0;
}

.footer-content.v3 .footer-widget .gallery-content li:hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.footer-content.v3 .footer-widget .gallery-content li:hover .gallery-btn {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer-content.v3 .footer-widget .widget-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: var(--color-white);
  padding-bottom: 15px;
  position: relative;
}

.footer-content.v3 .footer-widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-primary);
  width: 41px;
  height: 3px;
}

.footer-content.v3 .footer-widget .quick-links {
  margin-top: 30px;
}

.footer-content.v3 .footer-widget .quick-links li {
  margin-bottom: 18px;
}

.footer-content.v3 .footer-widget .quick-links li a {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--color-white);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer-content.v3 .footer-widget .quick-links li a::before {
  content: "";
  font-family: "icomoon";
  font-size: calc(100% - 5px);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer-content.v3 .footer-widget .quick-links li:hover a,
.footer-content.v3 .footer-widget .quick-links li:hover::before {
  color: var(--color-primary);
}

.footer-content.v3 .footer-widget .get-newsletter {
  margin-top: 30px;
}

.footer-content.v3 .footer-widget .get-newsletter .para {
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-white);
}

.footer-content.v3 .footer-widget .get-newsletter .email-address {
  margin-top: 21px;
  background: var(--color-white);
  border-radius: 5px;
  margin-bottom: 20px;
}

.footer-content.v3 .footer-widget .get-newsletter .email-address form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
}

.footer-content.v3 .footer-widget .get-newsletter .email-address form input {
  width: 100%;
}

.footer-content.v3 .footer-widget .get-newsletter .email-address form input::-webkit-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v3 .footer-widget .get-newsletter .email-address form input::-moz-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v3 .footer-widget .get-newsletter .email-address form input:-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v3 .footer-widget .get-newsletter .email-address form input::-ms-input-placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.footer-content.v3 .footer-widget .get-newsletter .email-address form input::placeholder {
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

/*-------------------
  Footer Contact Info
  -------------------*/
.footer-contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 80px !important;
}

.footer-contact-info .contact-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  border-radius: 15px;
  border: 1px dashed var(--color-primary);
  padding: 22px 10px;
}

.footer-contact-info .contact-data .data-icon {
  width: 70px;
  height: 70px;
  background: var(--color-white);
  stroke-width: 1px;
  border: 1px solid var(--color-primary);
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-primary);
  font-size: 24px;
}

.footer-contact-info .contact-data .para,
.footer-contact-info .contact-data .title {
  color: var(--color-white);
}

.footer-contact-info .contact-data .title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
}

.footer-contact-info .contact-data .para {
  font-family: Kanit;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

/*-------------------
  Footer Contact Info
  -------------------*/
.footer-section.v3 {
  background-image: url("../../assets/img/footer/v3/bg-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

/*-------------------
  Copy Right Section
  -------------------*/
.copyright-section.v1 {
  background: #2c465d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 23px 0;
}

.copyright-section.v1 .copyright-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
}

.copyright-section.v1 .copyright-content .right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 33px;
}

.copyright-section.v1 .para {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.copyright-section.v1 .para span {
  color: var(--color-primary);
}

.copyright-section.v1 .para2 {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.copyright-section.v1 .para2:hover {
  color: var(--color-primary);
}

.copyright-section.v2 {
  border-top: 1px solid #373737;
  background: #0b1813;
  padding: 23px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.copyright-section.v2 .copyright-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.copyright-section.v2 .copyright-content .right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
}

.copyright-section.v2 .para {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.copyright-section.v2 .para span {
  color: var(--color-primary);
}

.copyright-section.v2 .para2 {
  color: var(--color-white);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.copyright-section.v2 .para2:hover {
  color: var(--color-primary);
}

/*-------------------
 Contact Info 3
  -------------------*/
.contact-info-3 {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  margin-bottom: -70px;
}

.contact-info-3 .contact-info-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
  -webkit-box-shadow: 0px 17px 38px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 17px 38px 0px rgba(0, 0, 0, 0.16);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.contact-info-3 .contact-info-data .location,
.contact-info-3 .contact-info-data .phone {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  padding: 35px 30px;
  background: var(--color-white);
}

.contact-info-3 .contact-info-data .location .title,
.contact-info-3 .contact-info-data .phone .title {
  font-family: Kanit;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  color: var(--color-black);
}

.contact-info-3 .contact-info-data .location .para,
.contact-info-3 .contact-info-data .phone .para {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-black);
  font-family: var(--title-font);
}

.contact-info-3 .contact-info-data .location .location-icon,
.contact-info-3 .contact-info-data .location .phone-icon,
.contact-info-3 .contact-info-data .phone .location-icon,
.contact-info-3 .contact-info-data .phone .phone-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--color-primary);
  font-size: 16px;
  color: var(--color-white);
}

.contact-info-3 .contact-info-data .follow-us {
  width: 100%;
  background: var(--color-primary);
  text-align: center;
  padding: 35px 0;
}

.contact-info-3 .contact-info-data .follow-us .title {
  color: var(--color-white);
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.contact-info-3 .contact-info-data .follow-us .follow-us-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  margin-top: 7px;
}

.contact-info-3 .contact-info-data .follow-us .follow-us-social li .social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: var(--color-white);
  border-radius: 7px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 12px;
  font-weight: 400;
}

.contact-info-3 .contact-info-data .follow-us .follow-us-social li:hover a {
  background: var(--color-black);
  color: var(--color-white);
}

.footer-social-link {
  margin-top: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.footer-social-link li a {
  width: 35px;
  height: 35px;
  background: var(--color-white);
  border-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 12px;
  font-weight: 400;
}

.footer-social-link li a:hover {
  background: #2c465d;
  color: var(--color-white);
}

.choose-us-accordion li {
  margin-bottom: 20px;
}

.choose-us-accordion li:last-child {
  margin-bottom: 0;
}

.choose-us-accordion .accordion-button {
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px !important;
  background: var(--color-primary);
  color: var(--color-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.choose-us-accordion .accordion-item {
  border-radius: 10px !important;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: var(--color-white);
}

.choose-us-accordion .accordion-item .accordion-header .accordion-button {
  color: var(--color-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.choose-us-accordion .accordion-item .accordion-header .accordion-button.collapsed {
  border: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: var(--color-white);
}

.choose-us-accordion .accordion-item .accordion-header .accordion-button.collapsed::after {
  content: "";
  font-family: "icomoon";
  width: 40px;
  height: 40px;
  background: var(--color-white);
  color: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
}

.choose-us-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: var(--color-white);
  border: none;
}

.choose-us-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "";
  font-family: "icomoon";
  width: 40px;
  height: 40px;
  background: var(--color-primary);
  color: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
}

.choose-us-accordion .accordion-item .accordion-body {
  padding: 0 20px 30px 30px;
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.why-choose-us-accordion li {
  margin-bottom: 20px;
}

.why-choose-us-accordion li:last-child {
  margin-bottom: 0;
}

.why-choose-us-accordion .accordion-button {
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px !important;
  background: var(--color-primary);
  color: var(--color-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.why-choose-us-accordion .accordion-item {
  border-radius: 10px !important;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: var(--color-white);
}

.why-choose-us-accordion .accordion-item .accordion-header .accordion-button {
  color: var(--color-black);
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.why-choose-us-accordion .accordion-item .accordion-header .accordion-button .button-number {
  background: #f6f7fa;
  color: var(--color-primary);
  border-radius: 50%;
  min-width: 34px;
  max-width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 16px;
}

.why-choose-us-accordion .accordion-item .accordion-header .accordion-button.collapsed {
  border: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: var(--color-white);
}

.why-choose-us-accordion .accordion-item .accordion-header .accordion-button.collapsed::after {
  content: "";
  font-family: "icomoon";
  width: 40px;
  height: 40px;
  background: var(--color-white);
  color: var(--color-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
  font-size: 16px;
}

.why-choose-us-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: var(--color-white);
  border: none;
}

.why-choose-us-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "";
  font-family: "icomoon";
  width: 40px;
  height: 40px;
  background: var(--color-primary);
  color: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
  font-size: 16px;
}

.why-choose-us-accordion .accordion-item .accordion-body {
  padding: 0 20px 30px 30px;
  color: var(--color-paragraph);
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100vh;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}

.mobile-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  color: var(--color-primary);
}

.mobile-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}

.mobile-menu-wrapper .menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: -16.5px;
  top: 25px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 36px;
  font-size: 14px;
  z-index: 1;
  color: #fff;
  color: var(--color-primary);
  border-radius: 50%;
}

.mobile-menu-wrapper .menu-toggle:hover {
  background-color: #1c1c1c;
  color: #fff;
}

.mobile-menu-wrapper .mobile-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: var(--color-white);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
  z-index: 1;
}

.mobile-menu-wrapper.body-visible {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-wrapper.body-visible .mobile-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}

.mobile-menu::-webkit-scrollbar {
  display: none;
}

.mobile-menu ul {
  margin: 0;
  padding: 0 0;
}

.mobile-menu ul li {
  border-bottom: 1px solid var(--color-border);
  list-style-type: none;
}

.mobile-menu ul li li:first-child {
  border-top: 1px solid var(--color-border);
}

.mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1.4;
  font-size: 16px;
  color: #1c1c1c;
}

.mobile-menu ul li.active-class>a {
  color: #179bd7;
}

.mobile-menu ul li.active-class>a:before {
  background: #179bd7;
  border-color: #179bd7;
}

.mobile-menu ul li ul li {
  padding-left: 20px;
}

.mobile-menu ul li ul li:last-child {
  border-bottom: none;
}

.mobile-menu ul .submenu-item-has-children>a .mean-expand-class {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background: #ddd;
  color: var(--body-color);
  -webkit-box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}

.mobile-menu ul .submenu-item-has-children>a .mean-expand-class:before {
  content: "";
  font-family: "icomoon";
  font-weight: 700;
}

.mobile-menu ul .submenu-item-has-children.active-class>a .mean-expand-class:before {
  content: "";
}

.mobile-menu>ul {
  padding: 0 40px;
}

.mobile-menu>ul>li:last-child {
  border-bottom: none;
}

@media (max-width: 400px) {
  .mobile-menu-wrapper .mobile-menu-area {
    width: 100%;
    max-width: 270px;
  }

  .mobile-menu>ul {
    padding: 0 20px;
  }
}

/*------------------- 4.2. Header  -------------------*/
.nav-header {
  position: relative;
  z-index: 41;
}

.nav-header .row {
  --bs-gutter-y: 0rem;
}

.sticky-wrapper {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.sticky-wrapper.sticky {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-animation: stickyAni 0.4s ease-in-out;
  animation: stickyAni 0.4s ease-in-out;
}

@-webkit-keyframes stickyAni {
  0% {
    -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
    transform: translate3d(0, -40px, 0) scaleY(0.8);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0) scaleY(1);
    transform: translate3d(0, 0, 0) scaleY(1);
    opacity: 1;
  }
}

@keyframes stickyAni {
  0% {
    -webkit-transform: translate3d(0, -40px, 0) scaleY(0.8);
    transform: translate3d(0, -40px, 0) scaleY(0.8);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0) scaleY(1);
    transform: translate3d(0, 0, 0) scaleY(1);
    opacity: 1;
  }
}

/****header-top*****/
.main-menu a {
  display: block;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}

.main-menu a:hover {
  color: #179bd7;
}

.main-menu>ul>li {
  margin: 0 11px;
}

.main-menu>ul>li>a {
  padding: 26px 0;
}

.main-menu>ul>li>a:hover {
  color: #179bd7;
}

.main-menu ul {
  margin: 0;
  padding: 0;
}

.main-menu ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}

.main-menu ul li:last-child {
  margin-right: 0 !important;
}

.main-menu ul li:first-child {
  margin-left: 0 !important;
}

.main-menu ul li:hover>ul.sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  z-index: 9;
}

.main-menu ul li:hover>ul.mega-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1) translateX(-50%);
  transform: scaleY(1) translateX(-50%);
  z-index: 9;
}

.main-menu ul li ul.mega-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 10px;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 10px 20px 10px 10px;
  left: 50%;
  -webkit-transform: scaleY(0) translateX(-50%);
  transform: scaleY(0) translateX(-50%);
}

.main-menu ul li ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  border: 0;
  padding-bottom: 15px;
}

.main-menu ul li ul.mega-menu li a {
  display: block;
  padding: 11px 15px;
  border-bottom: 1px solid rgba(223, 223, 223, 0.87);
  color: #1c1c1c;
}

.main-menu ul li ul.mega-menu li a:hover {
  background: transparent;
  color: #1c1c1c;
}

.main-menu ul li ul.mega-menu li ul li {
  border: 0;
  padding: 0;
}

.main-menu ul li ul.mega-menu li ul li a {
  border: 0;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 400;
}

.main-menu ul li ul.mega-menu li ul li a:hover {
  color: #179bd7;
}

.main-menu ul.mega-menu,
.main-menu ul.sub-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: -20px;
  background-color: #fff;
  visibility: hidden;
  min-width: 190px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0px;
  opacity: 0;
  z-index: -1;
  border: 0;
  -webkit-box-shadow: 0px 4px 15px rgba(1, 15, 28, 0.06);
  box-shadow: 0px 4px 15px rgba(1, 15, 28, 0.06);
  border-radius: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.main-menu ul.mega-menu li,
.main-menu ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 0px;
}

.main-menu ul.mega-menu a,
.main-menu ul.sub-menu a {
  color: var(--color-primary);
  padding: 12px 20px;
  line-height: 30px;
  position: relative;
}

.main-menu ul.mega-menu a:hover,
.main-menu ul.sub-menu a:hover {
  background: #179bd7;
  color: #fff;
}

.main-menu ul.mega-menu ul.sub-menu,
.main-menu ul.sub-menu ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 0px;
}

.main-menu ul.mega-menu ul.sub-menu li ul,
.main-menu ul.sub-menu ul.sub-menu li ul {
  left: 100%;
  right: auto;
}

.simple-icon {
  border: none;
  background-color: transparent;
  color: #1c1c1c;
  padding: 0;
  font-size: 22px;
  position: relative;
}

.simple-icon .badge {
  padding: 0.2em 0.4em;
  font-size: 0.5em;
  top: -5px;
  right: 0px;
}

.simple-icon:has(.badge) {
  padding-right: 8px;
}

.header-button {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 40px;
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .header-button {
    gap: 20px;
  }
}

.social-links .social-title {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  margin: 0 15px 0 0;
  color: #fff;
}

.social-links a {
  font-size: 15px;
  display: inline-block;
  color: var(--body-color);
  margin: 0 20px 0 0;
}

.social-links a:last-child {
  margin-right: 0;
}

.social-links a:hover {
  color: #179bd7;
}

.header-links>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-links li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 500;
}

.header-links li:not(:last-child) {
  margin: 0 40px 0 0;
}

.header-links li>i {
  margin-right: 8px;
}

.header-links li .header-sub-links li:not(:last-child) {
  margin: 0 30px 0 0;
}

.header-links li .header-sub-links li:not(:last-child):before {
  content: "/";
  position: absolute;
  right: -20px;
  top: 0;
  color: #1c1c1c;
}

.header-links li,
.header-links span,
.header-links p,
.header-links a {
  color: #fff;
  font-family: var(--body-font);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-links a:hover {
  color: #179bd7;
}

.header-links b,
.header-links strong {
  font-weight: 600;
  margin-right: 6px;
}

.header-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-btn {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  background: transparent;
  color: #1c1c1c;
  font-family: var(--body-font);
  position: relative;
  padding-right: 65px;
}

.sidebar-btn:before,
.sidebar-btn:after {
  content: "";
  position: absolute;
  right: 0;
  top: 9px;
  width: 50px;
  height: 1px;
  background: #1c1c1c;
}

.sidebar-btn:before {
  -webkit-transform: translate(0, 7px);
  transform: translate(0, 7px);
}